@import 'bootstrap/scss/mixins/breakpoints';

.slick-initialized {
    @include media-breakpoint-up(lg) {
        .slick-slide {
            padding-right: rem(16);
        }
    }

    .slick-track {
        margin-left: 0;
    }

    .slick-slide {
        height: auto;

        @include media-breakpoint-down(lg) {
            -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
            transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        }
    }
}

.slick-vertical {
    @include media-breakpoint-up(lg) {
        .slick-slide {
            padding-right: rem(0);
        }
    }
}

.next-arrow {
    width: rem(36);
    height: rem(52);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: rem(2);
    position: absolute;
    right: rem(40);
    z-index: 20;
    top: 31%;

    &::after {
        content: '';
        background-image: url(../images/right-icon.svg);
        height: rem(16);
        width: rem(16);
        filter: invert(1);
        position: absolute;
        transform: translateY(-50%) translateX(-50%);
        background-size: contain;
    }
}

.prev-arrow {
    width: rem(36);
    height: rem(52);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: rem(2);
    position: absolute;
    left: rem(16);
    z-index: 20;
    top: 31%;

    &::after {
        content: '';
        background-image: url(../images/left-icon.svg);
        height: rem(16);
        width: rem(16);
        filter: invert(1);
        position: absolute;
        transform: translateY(-50%) translateX(-50%);
        background-size: contain;
    }
}

.slide-arrow.slick-disabled {
    display: none !important;
}

.slick-scrollbar-tabs {
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.tab-content {
    .slick-scrollbar-cta {
        bottom: 0;
        @include media-breakpoint-up(lg) {
            bottom: 45px;
        }
    }
}

.slick-scrollbar-cta {
    position: relative;
    bottom: -5px;
    @include media-breakpoint-up(lg) {
        bottom: 45px;
    }

    .cta-link-ctn {
        .cta-link {
            border: 1px solid $silver-dark-gray;
            border-radius: 0.4rem;
            padding: 10px 14px;
            text-decoration: none;
        }
    }
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 20px;
            height: 20px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;

            &:hover,
            &:focus {
                //outline: none;
                &::before {
                    opacity: 1;
                }
            }

            &::before {
                font-family: 'slick';
                font-size: 30px;
                line-height: 20px;
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                content: '•';
                text-align: center;
                opacity: 0.4;
                color: black;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.slick-active button::before {
            opacity: 1;
            color: black;
        }
    }
}

.slick-scrollbar-cta .slick-dots {
    position: relative;
    bottom: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    border-radius: 20px;
    background: #00000059;

    li {
        position: relative;
        display: inline-block;
        width: 2rem;
        height: 4px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        @include media-breakpoint-up(lg) {
            width: 4rem;
        }

        button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 2rem;
            height: 0;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;
            @include media-breakpoint-up(lg) {
                width: 4rem;
            }

            &::before {
                content: "";
            }

            &:hover,
            &:focus {
                //outline: none;
                &::before {
                    opacity: 1;
                }

                .slick-dot-icon {
                    opacity: 0;
                }
            }

            .slick-dot-icon {
                opacity: 0;
            }
        }

        &.slick-active {
            background: black;
            border-radius: 20px;

            button {
                .slick-dot-icon {
                    opacity: 0;
                }
            }
        }
    }
}

.carousel {
    .slick-dots {
        position: unset;
    }
}

#product-recommender-bisn {
    @include media-breakpoint-up(lg) {
        .carousel-ctn {
            margin-left: 1rem !important;

            .horizontal-product-carousel {
                .product-tile.product-tile-pd {
                    width: rem(213);
                }
            }
        }
    }

    span.product-name {
        font-size: rem(14);
        line-height: rem(24);
    }

    .slick-dots li {
        width: 1.5rem;

        button {
            width: 1.5rem;
            @include media-breakpoint-up(lg) {
                width: 1.5rem;
            }
        }
    }
}

#bisnProductModal {
    .modal-content {
        overflow-x: hidden;
    }
}

#product-recommender-pgp {
    .next-arrow {
        top: 26%;
    }

    .prev-arrow {
        top: 26%;
    }
}

#pdt-recommendations {
    .next-arrow {
        top: 30%;
    }

    .prev-arrow {
        top: 30%;
    }
}

.category-carousel {
    .next-arrow {
        top: 35%;
    }

    .prev-arrow {
        top: 35%;
    }
}
