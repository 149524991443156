@charset "UTF-8";
/* overriding the bootstrap header sizes */
[class*="col-"] {
  padding-right: 8px;
  padding-left: 8px; }

.row {
  margin-right: -8px;
  margin-left: -8px; }

@media (max-width: 991.98px) {
  #maincontent .full-width {
    margin-left: -16px;
    margin-right: -16px; } }

.pull-left-xs {
  float: left; }

.pull-right-xs {
  float: right; }

@media (min-width: 544px) and (max-width: 768.98px) {
  .pull-left-sm {
    float: left; }
  .pull-right-sm {
    float: right; } }

@media (min-width: 769px) and (max-width: 991.98px) {
  .pull-left-md {
    float: left; }
  .pull-right-md {
    float: right; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .pull-left-lg {
    float: left; }
  .pull-right-lg {
    float: right; } }

@media (min-width: 1200px) {
  .pull-left-xl {
    float: left; }
  .pull-right-xl {
    float: right; } }

@media (max-width: 543.98px) {
  .hidden-xs-down {
    display: none !important; } }

@media (max-width: 768.98px) {
  .hidden-sm-down {
    display: none !important; } }

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none !important; } }

@media (max-width: 1199.98px) {
  .hidden-lg-down {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.row.equal-height > [class^="col"] {
  display: flex; }

@media (max-width: 543.98px) {
  .collapsible-xs .title {
    line-height: 2.5rem;
    /* 40/16 */ }
    .collapsible-xs .title::after {
      display: block;
      clear: both;
      content: ""; }
    .collapsible-xs .title::after {
      float: right;
      content: "\f078";
      font-family: "FontAwesome"; }
  .collapsible-xs .content,
  .collapsible-xs .card-body {
    display: none; }
  .collapsible-xs.active .title::after {
    content: "\f077";
    margin-top: -0.125em;
    /* 2/16 */ }
  .collapsible-xs.active .content,
  .collapsible-xs.active .card-body {
    display: block; } }

.container div.collapsible-xs button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent; }
  .container div.collapsible-xs button.title:hover {
    text-decoration: none; }

@media (max-width: 768.98px) {
  .collapsible-sm .title {
    line-height: 2.5rem;
    /* 40/16 */ }
    .collapsible-sm .title::after {
      display: block;
      clear: both;
      content: ""; }
    .collapsible-sm .title::after {
      float: right;
      content: "\f078";
      font-family: "FontAwesome"; }
  .collapsible-sm .content,
  .collapsible-sm .card-body {
    display: none; }
  .collapsible-sm.active .title::after {
    content: "\f077";
    margin-top: -0.125em;
    /* 2/16 */ }
  .collapsible-sm.active .content,
  .collapsible-sm.active .card-body {
    display: block; } }

.container div.collapsible-sm button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent; }
  .container div.collapsible-sm button.title:hover {
    text-decoration: none; }

@media (max-width: 991.98px) {
  .collapsible-md .title {
    line-height: 2.5rem;
    /* 40/16 */ }
    .collapsible-md .title::after {
      display: block;
      clear: both;
      content: ""; }
    .collapsible-md .title::after {
      float: right;
      content: "\f078";
      font-family: "FontAwesome"; }
  .collapsible-md .content,
  .collapsible-md .card-body {
    display: none; }
  .collapsible-md.active .title::after {
    content: "\f077";
    margin-top: -0.125em;
    /* 2/16 */ }
  .collapsible-md.active .content,
  .collapsible-md.active .card-body {
    display: block; } }

.container div.collapsible-md button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent; }
  .container div.collapsible-md button.title:hover {
    text-decoration: none; }

@media (max-width: 1199.98px) {
  .collapsible-lg .title {
    line-height: 2.5rem;
    /* 40/16 */ }
    .collapsible-lg .title::after {
      display: block;
      clear: both;
      content: ""; }
    .collapsible-lg .title::after {
      float: right;
      content: "\f078";
      font-family: "FontAwesome"; }
  .collapsible-lg .content,
  .collapsible-lg .card-body {
    display: none; }
  .collapsible-lg.active .title::after {
    content: "\f077";
    margin-top: -0.125em;
    /* 2/16 */ }
  .collapsible-lg.active .content,
  .collapsible-lg.active .card-body {
    display: block; } }

.container div.collapsible-lg button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent; }
  .container div.collapsible-lg button.title:hover {
    text-decoration: none; }

.collapsible-xl .title {
  line-height: 2.5rem;
  /* 40/16 */ }
  .collapsible-xl .title::after {
    display: block;
    clear: both;
    content: ""; }
  .collapsible-xl .title::after {
    float: right;
    content: "\f078";
    font-family: "FontAwesome"; }

.collapsible-xl .content,
.collapsible-xl .card-body {
  display: none; }

.collapsible-xl.active .title::after {
  content: "\f077";
  margin-top: -0.125em;
  /* 2/16 */ }

.collapsible-xl.active .content,
.collapsible-xl.active .card-body {
  display: block; }

.container div.collapsible-xl button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent; }
  .container div.collapsible-xl button.title:hover {
    text-decoration: none; }

footer {
  background-color: #e9ecef;
  padding-top: 1.25em;
  /* 20/16 */
  padding-bottom: 1.25em; }
  footer h2 {
    font-size: 0.875rem;
    margin-bottom: 0;
    line-height: 2.5em;
    /* 40/16 */ }
  footer ul {
    list-style: none;
    padding-left: 0; }
  footer .social h2 {
    margin-top: 0; }
  footer .social::after {
    display: block;
    clear: both;
    content: ""; }
  footer .copyright,
  footer .social {
    margin-top: 1.25em; }
  footer .footer-container .footer-item.collapsible-xs button {
    font-family: 'Dosis', sans-serif;
    padding: 0; }
  footer .social-links {
    float: left; }
    footer .social-links::after {
      display: block;
      clear: both;
      content: ""; }
    @media (max-width: 543.98px) {
      footer .social-links {
        width: 80%; } }
    footer .social-links li {
      float: left;
      margin: 0.313em; }
      @media (max-width: 543.98px) {
        footer .social-links li {
          width: 20%;
          text-align: center; } }
    footer .social-links a {
      font-size: 2.25em; }
      footer .social-links a:hover {
        text-decoration: none; }
  @media (max-width: 543.98px) {
    footer .store {
      border-bottom: 1px solid #444; }
      footer .store .content {
        display: none; }
      footer .store h2::after {
        display: block;
        clear: both;
        content: ""; }
      footer .store h2::after {
        font-family: "FontAwesome";
        float: right;
        content: "\f041"; } }
  footer .content {
    font-size: 0.875em; }
    footer .content li {
      height: 1.875rem; }
  footer .copyright,
  footer .postscript {
    font-size: 0.8125em; }
  footer .copyright {
    margin-bottom: 0.625em; }
  footer .back-to-top {
    margin: 0.1em 0.313em;
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none; }
    footer .back-to-top i.fa-arrow-up {
      color: rgba(0, 0, 0, 0.7); }
    footer .back-to-top i.fa-circle {
      text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); }

@media (max-width: 543.98px) {
  .footer-item {
    border-bottom: 1px solid #444; } }

#consent-tracking .button-wrapper button {
  margin: 0.5em; }

.email-signup-message {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%; }

.email-signup-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em; }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .email-signup-alert.show {
    display: block; }

/* 20/16 */
.info-icon {
  position: relative;
  cursor: pointer;
  display: inline-block;
  border: none;
  padding: 0;
  background: inherit; }
  .info-icon .icon {
    border-radius: 1.25rem;
    background-color: var(--skin-primary-color-1);
    width: 1.5625rem;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-weight: 600; }
  .info-icon .tooltip {
    position: absolute;
    bottom: 100%;
    padding: 0.312rem;
    border-radius: 0.1875rem;
    background-color: #444;
    color: #fff;
    font-size: 0.928rem;
    min-width: 20rem;
    max-width: 15rem;
    transform: translate(-50%, -0.625em);
    left: 50%;
    margin-left: 4px;
    animation: fade-in 0.5s linear forwards; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    .info-icon .tooltip::before {
      content: " ";
      position: absolute;
      display: block;
      height: 1.25em;
      /* 20/16 */
      left: 0;
      bottom: -1.25em;
      width: 100%; }
    .info-icon .tooltip::after {
      border-left: solid transparent 0.625em;
      /* 10/16 */
      border-right: solid transparent 0.625em;
      /* 10/16 */
      border-top: solid #444 0.625em;
      bottom: -0.625em;
      content: " ";
      height: 0;
      left: 50%;
      margin-left: -13px;
      position: absolute;
      width: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover .slick-prev-icon:before, .slick-prev:hover .slick-next-icon:before, .slick-prev:focus .slick-prev-icon:before, .slick-prev:focus .slick-next-icon:before,
  .slick-next:hover .slick-prev-icon:before,
  .slick-next:hover .slick-next-icon:before,
  .slick-next:focus .slick-prev-icon:before,
  .slick-next:focus .slick-next-icon:before {
    opacity: 1; }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    cursor: default; }
    .slick-prev.slick-disabled .slick-prev-icon:before, .slick-prev.slick-disabled .slick-next-icon:before,
    .slick-next.slick-disabled .slick-prev-icon:before,
    .slick-next.slick-disabled .slick-next-icon:before {
      opacity: 0.25; }
  .slick-prev .slick-prev-icon:before, .slick-prev .slick-next-icon:before,
  .slick-next .slick-prev-icon:before,
  .slick-next .slick-next-icon:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev .slick-prev-icon:before {
    content: "←"; }
    [dir="rtl"] .slick-prev .slick-prev-icon:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next .slick-next-icon:before {
    content: "→"; }
    [dir="rtl"] .slick-next .slick-next-icon:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover .slick-dot-icon, .slick-dots li button:focus .slick-dot-icon {
          opacity: 1; }
      .slick-dots li button .slick-dot-icon {
        color: black;
        opacity: 0.25; }
        .slick-dots li button .slick-dot-icon:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "•";
          width: 20px;
          height: 20px;
          font-family: "slick";
          font-size: 6px;
          line-height: 20px;
          text-align: center; }
    .slick-dots li.slick-active button .slick-dot-icon {
      color: black;
      opacity: 0.75; }

/**
 Improved .sr-only class by ffoodd: https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
 */
.slick-sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
  /* 3 */ }

.slick-autoplay-toggle-button {
  position: absolute;
  left: 5px;
  bottom: -25px;
  z-index: 10;
  opacity: 0.75;
  background: none;
  border: 0;
  cursor: pointer; }
  .slick-autoplay-toggle-button:hover, .slick-autoplay-toggle-button:focus {
    outline: none;
    opacity: 1; }
  .slick-autoplay-toggle-button .slick-pause-icon:before {
    content: "⏸";
    width: 20px;
    height: 20px;
    font-family: "slick";
    font-size: 16px;
    line-height: 20px;
    text-align: center; }
  .slick-autoplay-toggle-button .slick-play-icon:before {
    content: "▶";
    width: 20px;
    height: 20px;
    font-family: "slick";
    font-size: 16px;
    line-height: 20px;
    text-align: center; }

@media (min-width: 992px) {
  .slick-initialized .slick-slide {
    padding-right: 1rem; } }

.slick-initialized .slick-track {
  margin-left: 0; }

.slick-initialized .slick-slide {
  height: auto; }
  @media (max-width: 1199.98px) {
    .slick-initialized .slick-slide {
      -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
      transition: all 1s cubic-bezier(0.7, 0, 0.3, 1); } }

@media (min-width: 992px) {
  .slick-vertical .slick-slide {
    padding-right: 0rem; } }

.next-arrow {
  width: 2.25rem;
  height: 3.25rem;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0.125rem;
  position: absolute;
  right: 2.5rem;
  z-index: 20;
  top: 31%; }
  .next-arrow::after {
    content: '';
    background-image: url(../images/right-icon.svg);
    height: 1rem;
    width: 1rem;
    filter: invert(1);
    position: absolute;
    transform: translateY(-50%) translateX(-50%);
    background-size: contain; }

.prev-arrow {
  width: 2.25rem;
  height: 3.25rem;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0.125rem;
  position: absolute;
  left: 1rem;
  z-index: 20;
  top: 31%; }
  .prev-arrow::after {
    content: '';
    background-image: url(../images/left-icon.svg);
    height: 1rem;
    width: 1rem;
    filter: invert(1);
    position: absolute;
    transform: translateY(-50%) translateX(-50%);
    background-size: contain; }

.slide-arrow.slick-disabled {
  display: none !important; }

.slick-scrollbar-tabs {
  display: none; }
  @media (min-width: 992px) {
    .slick-scrollbar-tabs {
      display: block; } }

.tab-content .slick-scrollbar-cta {
  bottom: 0; }
  @media (min-width: 992px) {
    .tab-content .slick-scrollbar-cta {
      bottom: 45px; } }

.slick-scrollbar-cta {
  position: relative;
  bottom: -5px; }
  @media (min-width: 992px) {
    .slick-scrollbar-cta {
      bottom: 45px; } }
  .slick-scrollbar-cta .cta-link-ctn .cta-link {
    border: 1px solid #abb0b4;
    border-radius: 0.4rem;
    padding: 10px 14px;
    text-decoration: none; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent; }
      .slick-dots li button:hover::before, .slick-dots li button:focus::before {
        opacity: 1; }
      .slick-dots li button::before {
        font-family: 'slick';
        font-size: 30px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: '•';
        text-align: center;
        opacity: 0.4;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button::before {
      opacity: 1;
      color: black; }

.slick-scrollbar-cta .slick-dots {
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  border-radius: 20px;
  background: #00000059; }
  .slick-scrollbar-cta .slick-dots li {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 4px;
    margin: 0;
    padding: 0;
    cursor: pointer; }
    @media (min-width: 992px) {
      .slick-scrollbar-cta .slick-dots li {
        width: 4rem; } }
    .slick-scrollbar-cta .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 2rem;
      height: 0;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent; }
      @media (min-width: 992px) {
        .slick-scrollbar-cta .slick-dots li button {
          width: 4rem; } }
      .slick-scrollbar-cta .slick-dots li button::before {
        content: ""; }
      .slick-scrollbar-cta .slick-dots li button:hover::before, .slick-scrollbar-cta .slick-dots li button:focus::before {
        opacity: 1; }
      .slick-scrollbar-cta .slick-dots li button:hover .slick-dot-icon, .slick-scrollbar-cta .slick-dots li button:focus .slick-dot-icon {
        opacity: 0; }
      .slick-scrollbar-cta .slick-dots li button .slick-dot-icon {
        opacity: 0; }
    .slick-scrollbar-cta .slick-dots li.slick-active {
      background: black;
      border-radius: 20px; }
      .slick-scrollbar-cta .slick-dots li.slick-active button .slick-dot-icon {
        opacity: 0; }

.carousel .slick-dots {
  position: unset; }

@media (min-width: 992px) {
  #product-recommender-bisn .carousel-ctn {
    margin-left: 1rem !important; }
    #product-recommender-bisn .carousel-ctn .horizontal-product-carousel .product-tile.product-tile-pd {
      width: 13.3125rem; } }

#product-recommender-bisn span.product-name {
  font-size: 0.875rem;
  line-height: 1.5rem; }

#product-recommender-bisn .slick-dots li {
  width: 1.5rem; }
  #product-recommender-bisn .slick-dots li button {
    width: 1.5rem; }
    @media (min-width: 992px) {
      #product-recommender-bisn .slick-dots li button {
        width: 1.5rem; } }

#bisnProductModal .modal-content {
  overflow-x: hidden; }

#product-recommender-pgp .next-arrow {
  top: 26%; }

#product-recommender-pgp .prev-arrow {
  top: 26%; }

#pdt-recommendations .next-arrow {
  top: 30%; }

#pdt-recommendations .prev-arrow {
  top: 30%; }

.category-carousel .next-arrow {
  top: 35%; }

.category-carousel .prev-arrow {
  top: 35%; }

#footercontent {
  background-color: #fff;
  padding-bottom: 0;
  overflow-x: hidden; }
  @media (max-width: 991.98px) {
    #footercontent.checkout-footer {
      border-top: 0.0625rem solid #ccc; } }
  @media (max-width: 991.98px) {
    #footercontent .footer-width {
      width: 100%; } }
  #footercontent .add-links a {
    color: #000 !important; }
  #footercontent .find-store {
    color: #151415; }
  #footercontent .email-signup {
    width: 100%;
    background-color: #151415;
    margin: 0;
    padding: 1.25rem;
    box-sizing: border-box; }
    #footercontent .email-signup .email-content-text {
      display: inline-block;
      color: #fff;
      vertical-align: middle;
      line-height: 1.5; }
      @media (max-width: 991.98px) {
        #footercontent .email-signup .email-content-text {
          width: 10rem;
          font-size: 1rem;
          font-weight: 700;
          line-height: 1.3125rem; } }
  #footercontent .footer-columns {
    margin-top: 3rem;
    margin-bottom: 2.5rem;
    color: #151415;
    font-size: 1rem;
    line-height: 2rem; }
    @media (max-width: 991.98px) {
      #footercontent .footer-columns {
        margin: auto; }
        #footercontent .footer-columns .column-content {
          margin: 0.7rem auto 2.5rem; }
          #footercontent .footer-columns .column-content .footer-item {
            border-bottom: none;
            padding: 0.25rem 0.5rem; } }
    @media (max-width: 991.98px) {
      #footercontent .footer-columns .footer-item {
        border: 0;
        padding: 0 0 2rem; }
        #footercontent .footer-columns .footer-item .logo img {
          width: 3.6875rem;
          height: 1.75rem; }
        #footercontent .footer-columns .footer-item .social-icons {
          margin-top: 1.5rem !important; } }
    #footercontent .footer-columns .footer-item .content {
      font-size: 0.875rem; }
    #footercontent .footer-columns .logo {
      max-width: 3.5rem; }
    #footercontent .footer-columns .title {
      color: #000;
      font-size: 0.875rem;
      font-weight: 700 !important;
      padding-bottom: 0.875rem;
      padding-top: 1.125rem;
      padding-right: 0;
      line-height: 1rem; }
    #footercontent .footer-columns ul li {
      height: auto; }
  #footercontent .footer-grey-box {
    background-color: #fafafa;
    padding-bottom: 1rem; }
    #footercontent .footer-grey-box .familybrands-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 0; }
      #footercontent .footer-grey-box .familybrands-content .title {
        width: auto; }
        @media (max-width: 991.98px) {
          #footercontent .footer-grey-box .familybrands-content .title {
            width: 100%; } }
      #footercontent .footer-grey-box .familybrands-content img {
        height: 2rem; }
      @media (max-width: 991.98px) {
        #footercontent .footer-grey-box .familybrands-content {
          margin: 2rem auto 1rem;
          padding: 1rem;
          flex-wrap: wrap;
          justify-content: left; }
          #footercontent .footer-grey-box .familybrands-content img {
            height: 1.5rem; } }
    #footercontent .footer-grey-box .familybrands-content > * {
      margin-right: 1.5rem; }
      @media (max-width: 991.98px) {
        #footercontent .footer-grey-box .familybrands-content > * {
          margin: 0.5rem 1rem 0.5rem 0; } }
    #footercontent .footer-grey-box .footer-additional-links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 0.875rem; }
      @media (max-width: 991.98px) {
        #footercontent .footer-grey-box .footer-additional-links {
          flex-direction: column; } }
      #footercontent .footer-grey-box .footer-additional-links .footer__aditional__container {
        display: flex;
        flex-direction: row; }
        @media (max-width: 991.98px) {
          #footercontent .footer-grey-box .footer-additional-links .footer__aditional__container {
            flex-direction: column; } }
      #footercontent .footer-grey-box .footer-additional-links .find-store {
        display: none; }
        @media (min-width: 992px) {
          #footercontent .footer-grey-box .footer-additional-links .find-store {
            display: block; } }
        #footercontent .footer-grey-box .footer-additional-links .find-store .select-country .country-selector-one {
          padding: 0rem; }
        #footercontent .footer-grey-box .footer-additional-links .find-store .select-country .country-img {
          display: flex;
          justify-content: flex-end; }
          #footercontent .footer-grey-box .footer-additional-links .find-store .select-country .country-img img {
            height: 1rem; }
            @media (min-width: 769px) {
              #footercontent .footer-grey-box .footer-additional-links .find-store .select-country .country-img img {
                height: 1.25rem; } }
        #footercontent .footer-grey-box .footer-additional-links .find-store .select-country .country-name {
          color: #000; }
      #footercontent .footer-grey-box .footer-additional-links .footer__aditional__container > div.add-links {
        margin: 0 1rem; }
        @media (max-width: 991.98px) {
          #footercontent .footer-grey-box .footer-additional-links .footer__aditional__container > div.add-links {
            margin: 0.5rem 0 0; } }
      @media (max-width: 991.98px) {
        #footercontent .footer-grey-box .footer-additional-links .additional-links .add-links {
          padding-left: 0.95rem;
          line-height: 1.6rem;
          font-size: 0.875rem;
          margin-bottom: 1rem; } }
      #footercontent .footer-grey-box .footer-additional-links .additional-links .add-links a {
        font-size: 0.8rem;
        white-space: nowrap;
        text-decoration: underline; }
        @media (max-width: 991.98px) {
          #footercontent .footer-grey-box .footer-additional-links .additional-links .add-links a {
            font-size: 0.7rem; } }
      #footercontent .footer-grey-box .footer-additional-links .copy {
        font-size: 0.75rem;
        text-align: right; }
        @media (max-width: 991.98px) {
          #footercontent .footer-grey-box .footer-additional-links .copy {
            text-align: left;
            margin-top: 2rem;
            font-size: 0.75rem; } }
  #footercontent .custom_footer_h2 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1rem;
    font-size: 0.875rem;
    font-weight: 700; }
  #footercontent .footer-image {
    max-height: 1.25rem; }
  #footercontent .didot-header {
    font-family: "ITC Garamond W01"; }

.checkout-footer {
  color: #000 !important; }
  @media (max-width: 991.98px) {
    .checkout-footer {
      padding-top: 0; } }
  @media (max-width: 991.98px) {
    .has-sticky-footer .checkout-footer {
      margin-bottom: 8.5rem; } }
  @media (max-width: 991.98px) {
    .checkout-footer.place-order-stage {
      margin-bottom: 9.125rem; } }
  @media (min-width: 769px) {
    .checkout-footer .needhelp {
      border-top: 1px solid #ccc; } }
  .checkout-footer .accept-cards-label {
    white-space: nowrap; }
  .checkout-footer .support-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #000; }
  .checkout-footer .c-faq,
  .checkout-footer .email {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: #000 !important; }
  .checkout-footer .telephone {
    font-size: 0.875rem;
    line-height: 1.25rem; }
    .checkout-footer .telephone a {
      color: #000 !important; }
  .checkout-footer .cards {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem; }
  .checkout-footer .copyright {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.5rem; }
  @media (max-width: 991.98px) {
    .checkout-footer .copy-right-container {
      margin-bottom: 1rem !important; } }

.backtotop {
  display: none;
  position: fixed;
  bottom: 24%;
  right: 5%;
  cursor: pointer; }
  .backtotop .goback {
    display: inline-block;
    width: 24px;
    height: 24px; }

.afterpay-widget .afterpay-image {
  width: 4.75rem;
  height: 2.5rem; }

#afterpay-tooltip-show .close-button {
  font-size: 3rem; }

@media (min-width: 769px) {
  .globalModal .modal-dialog {
    width: 34.5rem;
    max-width: 34.5rem; } }

.globalModal .modal-content {
  position: relative; }
  @media (min-width: 769px) {
    .globalModal .modal-content {
      padding: 0; } }
  .globalModal .modal-content .close {
    position: absolute;
    right: 1rem;
    padding: 1rem; }
  .globalModal .modal-content .modal-body {
    margin-top: 5.0625rem; }
    @media (min-width: 769px) {
      .globalModal .modal-content .modal-body {
        margin-top: 7.5rem;
        padding: 0; } }
    @media (min-width: 769px) {
      .globalModal .modal-content .modal-body .email-content,
      .globalModal .modal-content .modal-body .privacy-policy {
        margin-left: 4.5rem;
        margin-right: 1.5rem; } }
    .globalModal .modal-content .modal-body .email-content input::placeholder {
      color: #000; }
    @media (min-width: 769px) {
      .globalModal .modal-content .modal-body .content .content-asset .w-100 {
        margin-left: 4.5rem !important;
        margin-right: 1.5rem !important;
        width: 368px !important; } }
    @media (min-width: 769px) {
      .globalModal .modal-content .modal-body .content .content-asset .subscribe-title {
        width: 24.5rem;
        margin-left: 4.5rem;
        margin-right: 5.5rem;
        padding-left: 1.875rem;
        padding-right: 1.875rem; } }

.store-locator-bottom-content .store #store-content {
  width: 17.1875rem; }

.store-locator-bottom-content .store #store-content-sec {
  width: 17.625rem; }

.footer-signup-modal .modal-body .nb-rewards {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem; }

.footer-signup-modal .modal-body .privacy-policy {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  margin-top: 1.5rem; }
  .footer-signup-modal .modal-body .privacy-policy a {
    text-decoration: underline;
    color: #000;
    font-weight: 500; }

@media (min-width: 992px) {
  #subscribe-global-modal .modal-header {
    padding-top: 2rem; } }

.myfit .fit-body-not-set,
.account-profile .fit-body-not-set {
  margin-top: 0.4375rem; }

.myfit .fit-links,
.account-profile .fit-links {
  font-weight: 500; }
  .myfit .fit-links a,
  .account-profile .fit-links a {
    text-decoration: underline; }

.myfit .fit-body-set,
.account-profile .fit-body-set {
  margin-top: 0.4375rem;
  color: #000 !important; }
  .myfit .fit-body-set .fit-row,
  .account-profile .fit-body-set .fit-row {
    margin-bottom: 1.1875rem; }
  .myfit .fit-body-set .fit-data,
  .account-profile .fit-body-set .fit-data {
    margin-bottom: 0.75rem; }

.account-profile .profile-firstname {
  margin-right: 6.25rem; }

.account-profile .profile-phone {
  margin-right: 1.25rem; }

.order-history .dot {
  width: 1.3125rem;
  height: 1.3125rem;
  border: solid;
  border-radius: 50%; }
  @media (max-width: 991.98px) {
    .order-history .dot {
      display: inline-table; } }

.order-history .dot-link {
  height: 0.125rem;
  width: 2.4rem;
  background-color: #000;
  margin-top: 0.5rem; }

.order-history .mr-auto {
  margin: 0 auto; }

.order-history .dot-container {
  width: 6rem; }
  @media (max-width: 991.98px) {
    .order-history .dot-container {
      width: 5.6rem; } }

.order-history .dot-number {
  color: #151415;
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1rem;
  text-align: center; }

.order-history .dot-number-cancelled {
  background-color: yellow; }

.order-history .order-text {
  font-size: 0.75rem;
  line-height: 1rem; }

.order-history .text-link {
  text-decoration: underline; }

.order-history .text-bold {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem; }

.order-history .activeDot {
  background: #000;
  color: #fff;
  border-color: #000; }

.order-history .pointer {
  cursor: pointer; }

.order-status {
  max-width: 62.0625rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6.5rem; }
  @media (max-width: 991.98px) {
    .order-status {
      margin-bottom: 5rem; } }

.guest-invoice-dowload {
  max-width: 62.0625rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6.5rem; }
  @media (max-width: 991.98px) {
    .guest-invoice-dowload {
      margin-bottom: 5rem; } }

.nb-video__product {
  position: relative;
  width: 100%; }
  .nb-video__product video {
    display: block;
    height: 100%;
    width: 100%; }

.experience-commerce_assets-HeroComponent .nb-video__product video,
.experience-commerce_assets-textRightImageComponent .nb-video__product video,
.experience-commerce_assets-textLeftImageComponent .nb-video__product video {
  height: 85vh;
  width: 100vw;
  background: #000; }
  @media (min-width: 544px) {
    .experience-commerce_assets-HeroComponent .nb-video__product video,
    .experience-commerce_assets-textRightImageComponent .nb-video__product video,
    .experience-commerce_assets-textLeftImageComponent .nb-video__product video {
      height: 100vh; } }
  @media only screen and (orientation: portrait) and (max-width: 1366px) {
    .experience-commerce_assets-HeroComponent .nb-video__product video,
    .experience-commerce_assets-textRightImageComponent .nb-video__product video,
    .experience-commerce_assets-textLeftImageComponent .nb-video__product video {
      height: calc(100vh - 90px); } }
  @media only screen and (orientation: landscape) and (max-width: 1366px) {
    .experience-commerce_assets-HeroComponent .nb-video__product video,
    .experience-commerce_assets-textRightImageComponent .nb-video__product video,
    .experience-commerce_assets-textLeftImageComponent .nb-video__product video {
      height: calc(100vh - 90px); } }

.experience-commerce_assets-HeroComponent .nb-video__product video::-webkit-media-controls-panel,
.experience-commerce_assets-textRightImageComponent .nb-video__product video::-webkit-media-controls-panel,
.experience-commerce_assets-textLeftImageComponent .nb-video__product video::-webkit-media-controls-panel {
  background-image: none !important; }

.experience-commerce_assets-HeroComponent .nb-video-non-modal video,
.experience-commerce_assets-textRightImageComponent .nb-video-non-modal video,
.experience-commerce_assets-textLeftImageComponent .nb-video-non-modal video {
  width: 100%; }
  @media (min-width: 992px) {
    .experience-commerce_assets-HeroComponent .nb-video-non-modal video,
    .experience-commerce_assets-textRightImageComponent .nb-video-non-modal video,
    .experience-commerce_assets-textLeftImageComponent .nb-video-non-modal video {
      height: 100%;
      width: 100%;
      object-fit: cover; } }

.experience-commerce_assets-HeroComponent .nb-video-non-modal video.frame-height,
.experience-commerce_assets-textRightImageComponent .nb-video-non-modal video.frame-height,
.experience-commerce_assets-textLeftImageComponent .nb-video-non-modal video.frame-height {
  height: auto; }
  @media (min-width: 992px) {
    .experience-commerce_assets-HeroComponent .nb-video-non-modal video.frame-height,
    .experience-commerce_assets-textRightImageComponent .nb-video-non-modal video.frame-height,
    .experience-commerce_assets-textLeftImageComponent .nb-video-non-modal video.frame-height {
      height: calc(100vh - 140px); } }

.experience-commerce_assets-HeroComponent .nb-video-non-modal .frame-height,
.experience-commerce_assets-textRightImageComponent .nb-video-non-modal .frame-height,
.experience-commerce_assets-textLeftImageComponent .nb-video-non-modal .frame-height {
  max-height: 22.5rem; }
  @media (min-width: 992px) {
    .experience-commerce_assets-HeroComponent .nb-video-non-modal .frame-height,
    .experience-commerce_assets-textRightImageComponent .nb-video-non-modal .frame-height,
    .experience-commerce_assets-textLeftImageComponent .nb-video-non-modal .frame-height {
      max-height: 42.5rem; } }

@media (min-width: 992px) {
  .experience-commerce_assets-HeroComponent .add-overlay .nb-video__product,
  .experience-commerce_assets-textRightImageComponent .add-overlay .nb-video__product,
  .experience-commerce_assets-textLeftImageComponent .add-overlay .nb-video__product {
    position: relative; }
  .experience-commerce_assets-HeroComponent .add-overlay .video-overlay,
  .experience-commerce_assets-textRightImageComponent .add-overlay .video-overlay,
  .experience-commerce_assets-textLeftImageComponent .add-overlay .video-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #000;
    pointer-events: none; }
    .experience-commerce_assets-HeroComponent .add-overlay .video-overlay.bg-white,
    .experience-commerce_assets-textRightImageComponent .add-overlay .video-overlay.bg-white,
    .experience-commerce_assets-textLeftImageComponent .add-overlay .video-overlay.bg-white {
      background-color: #fff; }
  .experience-commerce_assets-HeroComponent .add-overlay .content-text-hero,
  .experience-commerce_assets-textRightImageComponent .add-overlay .content-text-hero,
  .experience-commerce_assets-textLeftImageComponent .add-overlay .content-text-hero {
    z-index: 2; } }

.experience-component .align-image-center {
  justify-content: center; }

.experience-component .align-image-right {
  justify-content: end; }

.experience-component .align-image-left {
  justify-content: start; }

@media (min-width: 992px) {
  .experience-component .w-lg-100 {
    width: 100% !important; }
  .experience-component .w-lg-75 {
    width: 75% !important; }
  .experience-component .w-lg-50 {
    width: 50% !important; }
  .experience-component .w-lg-25 {
    width: 25% !important; }
  .experience-component .align-image-lg-center {
    justify-content: center; }
  .experience-component .align-image-lg-right {
    justify-content: end; }
  .experience-component .align-image-lg-left {
    justify-content: start; } }

.experience-component .forced-ratio {
  position: relative;
  width: 100%;
  height: 0; }
  .experience-component .forced-ratio.aspect1x1 {
    padding-top: 100%; }
  .experience-component .forced-ratio.aspect16x9 {
    padding-top: 56.25%; }
  .experience-component .forced-ratio.aspect5x4 {
    padding-top: 80%; }
  .experience-component .forced-ratio.aspect9x16 {
    padding-top: 177.78%; }
  .experience-component .forced-ratio.aspect4x5 {
    padding-top: 125%; }
  .experience-component .forced-ratio.device-ajusted-ratio {
    padding-top: 56.25%; }
    @media (max-width: 991.98px) {
      .experience-component .forced-ratio.device-ajusted-ratio {
        padding-top: 100%; } }
  @media (min-width: 992px) {
    .experience-component .forced-ratio.mobileonly {
      padding-top: 0 !important;
      height: auto !important; }
      .experience-component .forced-ratio.mobileonly video,
      .experience-component .forced-ratio.mobileonly video.frame-height,
      .experience-component .forced-ratio.mobileonly img {
        position: relative !important; } }
  @media (max-width: 991.98px) {
    .experience-component .forced-ratio.aspect-mob-1x1 {
      padding-top: 100%; }
    .experience-component .forced-ratio.aspect-mob-16x9 {
      padding-top: 56.25%; }
    .experience-component .forced-ratio.aspect-mob-5x4 {
      padding-top: 80%; }
    .experience-component .forced-ratio.aspect-mob-9x16 {
      padding-top: 177.78%; }
    .experience-component .forced-ratio.aspect-mob-4x5 {
      padding-top: 125%; }
    .experience-component .forced-ratio.deskonly {
      padding-top: 0 !important;
      height: auto !important; }
      .experience-component .forced-ratio.deskonly video,
      .experience-component .forced-ratio.deskonly video.frame-height,
      .experience-component .forced-ratio.deskonly img {
        position: relative !important; } }
  .experience-component .forced-ratio video,
  .experience-component .forced-ratio video.frame-height,
  .experience-component .forced-ratio img {
    height: 100%;
    max-height: none;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0; }
    @media (min-width: 992px) {
      .experience-component .forced-ratio video,
      .experience-component .forced-ratio video.frame-height,
      .experience-component .forced-ratio img {
        height: 100%;
        max-height: none; } }

/* overriding the bootstrap header sizes */
.product-tile.product-tile-pd .color-swatches {
  display: none;
  position: relative;
  height: 2.5rem; }
  @media (max-width: 991.98px) {
    .product-tile.product-tile-pd .color-swatches {
      display: none; } }
  .product-tile.product-tile-pd .color-swatches .swatches {
    overflow: hidden;
    white-space: nowrap;
    width: calc(100% - 36px); }
    .product-tile.product-tile-pd .color-swatches .swatches .getproduct {
      width: max-content;
      height: 2.6rem; }
      .product-tile.product-tile-pd .color-swatches .swatches .getproduct .slides {
        display: inline-block; }
        .product-tile.product-tile-pd .color-swatches .swatches .getproduct .slides.inactive {
          display: none; }
    .product-tile.product-tile-pd .color-swatches .swatches button {
      background-color: transparent;
      border: 0;
      background-size: 1rem;
      width: 1.5rem;
      height: 1.5rem; }
      .product-tile.product-tile-pd .color-swatches .swatches button:disabled {
        opacity: 0.4;
        cursor: auto; }
    .product-tile.product-tile-pd .color-swatches .swatches .prev {
      position: absolute;
      top: 46%;
      left: 0;
      z-index: 999; }
      .product-tile.product-tile-pd .color-swatches .swatches .prev button.prev {
        background-image: url("../images/left-icon.svg");
        background-repeat: no-repeat; }
    .product-tile.product-tile-pd .color-swatches .swatches .next {
      position: absolute;
      right: -2%;
      top: 28%; }
      .product-tile.product-tile-pd .color-swatches .swatches .next button.next {
        background-image: url("../images/right-icon.svg");
        background-repeat: no-repeat; }
  .product-tile.product-tile-pd .color-swatches .swatch-circle {
    width: 2.5rem;
    height: 2.5rem;
    border: 0; }

.product-tile.product-tile-pd .strike-through {
  color: #767676; }

.product-tile.product-tile-pd .content-color-swatches {
  height: 2.5rem; }

.product-tile.product-tile-pd .nb-button.button-secondary.calendarstack-btn {
  margin-top: 10px; }

.product-tile.product-tile-pd .badges {
  position: absolute;
  top: 0.375rem; }
  .product-tile.product-tile-pd .badges .sub-badges {
    color: #fff;
    font-size: 0.875rem;
    line-height: 1rem;
    background-color: #000;
    height: 1.5rem; }
  .product-tile.product-tile-pd .badges .sub-badges.dailyDeal {
    background-color: #cf0a2c; }

.product-tile.product-tile-pd .tile-badges .sub-badges {
  color: #b36205;
  font-size: 0.875rem; }

.experience-commerce_assets-CalendarStack .product-grid .font-header-2 {
  margin-top: 15px; }
  .experience-commerce_assets-CalendarStack .product-grid .font-header-2:first-child {
    margin-top: 20px; }

.shopnow-products .product-tile.product-tile-pd .nb-button.button-secondary.calendarstack-btn {
  background-color: #cf0a2c;
  color: #fff !important; }
  .shopnow-products .product-tile.product-tile-pd .nb-button.button-secondary.calendarstack-btn:hover {
    background-color: #b90020;
    border-color: #b90020; }
  .shopnow-products .product-tile.product-tile-pd .nb-button.button-secondary.calendarstack-btn:focus {
    outline: 2px auto highlight;
    outline: 2px auto -webkit-focus-ring-color;
    box-shadow: none; }

.outofstock-products .product-tile.product-tile-pd .nb-button.button-secondary.calendarstack-btn {
  display: none; }

.outofstock-products .product-tile.product-tile-pd .image-container {
  opacity: 0.3;
  transition: opacity 0.7s; }
  .outofstock-products .product-tile.product-tile-pd .image-container:hover {
    opacity: 1; }

#selectProductSizeModal .modal-header {
  border: none;
  padding: 1rem 0 2rem 0.5rem; }
  #selectProductSizeModal .modal-header h3 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1rem;
    font-weight: 700;
    margin-bottom: 0; }
    @media (max-width: 991.98px) {
      #selectProductSizeModal .modal-header h3 {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 500; } }
  @media (max-width: 991.98px) {
    #selectProductSizeModal .modal-header {
      padding-bottom: 0; } }
  #selectProductSizeModal .modal-header .close {
    padding: 0.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 99; }
    @media (max-width: 991.98px) {
      #selectProductSizeModal .modal-header .close {
        margin: 0;
        padding: 0 0.5rem; } }

#selectProductSizeModal .modal-body {
  margin-top: 1.5rem;
  padding-left: 1rem;
  padding-bottom: 0; }
  #selectProductSizeModal .modal-body .product-name {
    margin-top: 1rem;
    margin-bottom: 0; }
  #selectProductSizeModal .modal-body .size-chart {
    display: none; }
  @media (max-width: 991.98px) {
    #selectProductSizeModal .modal-body {
      margin-top: 0;
      padding-left: 0.5rem; } }

#selectProductSizeModal .modal-footer {
  position: static;
  padding-top: 0.25rem; }
  #selectProductSizeModal .modal-footer .cart-and-ipay {
    text-align: left;
    padding: 0 0.5rem 1rem 2rem; }
    #selectProductSizeModal .modal-footer .cart-and-ipay .add-to-cart-global {
      width: 100%;
      margin: 0; }
      #selectProductSizeModal .modal-footer .cart-and-ipay .add-to-cart-global .fa-shopping-bag {
        display: none; }
  @media (max-width: 991.98px) {
    #selectProductSizeModal .modal-footer {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      padding-top: 0; }
      #selectProductSizeModal .modal-footer .cart-and-ipay {
        padding: 1rem 0.5rem; }
      #selectProductSizeModal .modal-footer .apple-pay-pdp {
        display: none; } }

#selectProductSizeModal .icon-prev,
#selectProductSizeModal .icon-next {
  border-radius: 2rem;
  padding-top: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center; }

#selectProductSizeModal .carousel-control-prev {
  left: 1rem;
  color: #fff; }

#selectProductSizeModal .carousel-control-next {
  right: 1rem;
  color: #fff; }

#selectProductSizeModal .prices {
  text-align: left; }

#selectProductSizeModal .availability-msg {
  font-weight: 700;
  color: #cf0a2c; }

@media (max-width: 543.98px) {
  #selectProductSizeModal .attributes .width-attribute {
    min-width: 6.3rem !important; } }

#selectProductSizeModal .attributes [data-attr="style"] {
  margin-bottom: 16px !important; }

#selectProductSizeModal .attributes .attribute div:first-child {
  margin-bottom: 0 !important; }

#selectProductSizeModal .attributes .attribute .attribute-label {
  margin-top: 0 !important; }

#selectProductSizeModal .attributes .attribute .color-display-name {
  margin-top: 1.5rem !important; }
  @media (max-width: 991.98px) {
    #selectProductSizeModal .attributes .attribute .color-display-name {
      margin-top: 0 !important;
      margin-bottom: 0.75rem; } }

#selectProductSizeModal .attributes .attribute .error-msg {
  display: none;
  color: #cf0a2c; }

#selectProductSizeModal .attributes .attribute.error .error-msg {
  display: inline-flex; }

#selectProductSizeModal .attributes .variation-attribute {
  border: 2px solid #efefef;
  padding: 0;
  margin-bottom: 0.2rem;
  background: #fff;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 24px;
  min-width: 3.5rem; }

#selectProductSizeModal .attributes .style-attribute {
  min-width: 3rem;
  height: 3.75rem;
  border: 0;
  border-radius: 0; }
  @media (max-width: 991.98px) {
    #selectProductSizeModal .attributes .style-attribute {
      width: 4rem;
      height: 4.75rem; } }

#selectProductSizeModal .attributes .style-value {
  width: 3rem;
  height: 3rem;
  display: inline-block;
  overflow: hidden;
  background-size: contain; }
  @media (max-width: 991.98px) {
    #selectProductSizeModal .attributes .style-value {
      width: 4rem;
      height: 4rem; } }

#selectProductSizeModal .attributes .variation-attribute:focus,
#selectProductSizeModal .attributes .variation-attribute.selected {
  background: #151415;
  color: #fff; }

#selectProductSizeModal .attributes .variation-attribute:not(.style-attribute).unselectable {
  color: #767676;
  background: #efefef;
  pointer-events: none; }

#selectProductSizeModal .attributes .style-attribute.fully-oos-ind {
  color: #767676;
  background: #fff;
  pointer-events: none; }

#selectProductSizeModal .attributes .style-attribute:focus,
#selectProductSizeModal .attributes .style-attribute.selected {
  background: linear-gradient(#fff 3.5rem, #bf2d37 0.25rem); }
  @media (max-width: 991.98px) {
    #selectProductSizeModal .attributes .style-attribute:focus,
    #selectProductSizeModal .attributes .style-attribute.selected {
      background: linear-gradient(#fff 4.5rem, #bf2d37 0.25rem); } }

#selectProductSizeModal .attributes .non-input-label {
  font-size: 1rem;
  font-weight: 500; }

#selectProductSizeModal .attributes .size-attribute {
  height: 3.5rem;
  border: 2px solid #efefef;
  background: #fff;
  margin-right: 8px !important;
  margin-bottom: 7px !important; }

#selectProductSizeModal .attributes .width-attribute {
  min-width: 7.5rem;
  height: 3rem;
  margin-right: 0.5rem !important; }

#selectProductSizeModal .update-cart-product-global {
  width: 100% !important;
  margin: 0 !important; }

#selectProductSizeModal .primary-images .carousel img {
  width: 100%;
  height: auto; }

#selectProductSizeModal .primary-images .carousel .carousel-control-prev,
#selectProductSizeModal .primary-images .carousel .carousel-control-next {
  display: flex; }
  @media (max-width: 991.98px) {
    #selectProductSizeModal .primary-images .carousel .carousel-control-prev,
    #selectProductSizeModal .primary-images .carousel .carousel-control-next {
      display: none; } }

#selectProductSizeModal .primary-images .carousel .carousel-indicators {
  bottom: -45px; }
  #selectProductSizeModal .primary-images .carousel .carousel-indicators li {
    display: inline-block;
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 50%;
    background-color: #737b82; }
    #selectProductSizeModal .primary-images .carousel .carousel-indicators li.active {
      background-color: #767676; }

.experience-commerce_assets-VerticalProductCarousel .product-tile:has(.tile-badges) .tile-badges {
  margin-bottom: -0.5rem !important; }

/* overriding the bootstrap header sizes */
.grid-tile .spacer-video-text-ctn {
  position: relative;
  overflow: hidden; }
  .grid-tile .spacer-video-text-ctn .spacer-element {
    padding-top: 157%; }
  .grid-tile .spacer-video-text-ctn .video-and-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .grid-tile .spacer-video-text-ctn .video-and-text figcaption.component-content div {
      margin: 0 auto;
      padding: 0; }
      .grid-tile .spacer-video-text-ctn .video-and-text figcaption.component-content div .headline,
      .grid-tile .spacer-video-text-ctn .video-and-text figcaption.component-content div .font-body-large {
        margin-bottom: 1rem; }
      @media (max-width: 991.98px) {
        .grid-tile .spacer-video-text-ctn .video-and-text figcaption.component-content div .headline {
          margin-bottom: 0.625rem; }
        .grid-tile .spacer-video-text-ctn .video-and-text figcaption.component-content div .font-body {
          margin-bottom: 10px; } }

.grid-tile .grid-tile-video-container {
  height: 100%;
  width: 100%; }
  .grid-tile .grid-tile-video-container .video-controls {
    top: 0;
    right: 0; }
    .grid-tile .grid-tile-video-container .video-controls button.volume {
      display: none; }
    .grid-tile .grid-tile-video-container .video-controls button.play-pause {
      margin-left: 0;
      padding: 8px; }
      @media (max-width: 991.98px) {
        .grid-tile .grid-tile-video-container .video-controls button.play-pause img {
          height: unset;
          left: unset; } }
  .grid-tile .grid-tile-video-container .grid-tile-video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    pointer-events: none;
    z-index: 0; }
    .grid-tile .grid-tile-video-container .grid-tile-video-overlay.bg-white {
      background-color: #fff; }
  .grid-tile .grid-tile-video-container video {
    object-fit: cover; }

.grid-tile .component-content {
  bottom: 0;
  height: 100%;
  left: 8px;
  right: 8px; }
  .grid-tile .component-content.one-tile-wide .headline,
  .grid-tile .component-content.one-tile-wide .font-body-large {
    margin-bottom: 1rem; }
  @media (max-width: 991.98px) {
    .grid-tile .component-content.one-tile-wide .headline {
      margin-bottom: 0.625rem; }
    .grid-tile .component-content.one-tile-wide .font-body {
      margin-bottom: 0.625rem; } }
  @media (min-width: 992px) {
    .grid-tile .component-content.one-tile-wide .component-btn {
      margin-bottom: calc(0.5rem + ((1vw - 9.92px) * 2.9018)); }
    .grid-tile .component-content.one-tile-wide .disclaimer {
      margin-bottom: calc(0.25rem + ((1vw - 9.92px) * 2.6786)); } }
  @media screen and (min-width: 1440px) {
    .grid-tile .component-content.one-tile-wide .headline {
      margin-bottom: 0.5rem; }
    .grid-tile .component-content.one-tile-wide .component-btn {
      margin-bottom: 1.3125rem; }
    .grid-tile .component-content.one-tile-wide .disclaimer {
      margin-bottom: 1rem; } }
  .grid-tile .component-content.two-three-tile-wide {
    margin-bottom: 2.5rem; }
    @media (min-width: 992px) {
      .grid-tile .component-content.two-three-tile-wide {
        bottom: 0;
        height: 100%; } }
  .grid-tile .component-content .offset-1 {
    margin: 0 auto;
    padding: 0; }

.grid-tile .component-overlay {
  top: 0;
  left: 0; }

.grid-tile .headline {
  font-size: calc(1.4rem + ((1vw - 3.75px) * 3.5714));
  line-height: 1.1; }
  @media (min-width: 992px) {
    .grid-tile .headline {
      font-size: calc(1.4rem + ((1vw - 9.92px) * 4.9107)); } }
  @media screen and (min-width: 1440px) {
    .grid-tile .headline {
      font-size: 2.8rem; } }

@media (min-width: 992px) {
  .grid-tile .font-body-large {
    margin-bottom: calc(0.625rem + ((1vw - 9.92px) * 1.3393)); } }

@media screen and (min-width: 1440px) {
  .grid-tile .font-body-large {
    margin-bottom: 1rem; } }

.grid-tile .component-btn {
  position: relative;
  z-index: 1;
  text-align: left;
  line-height: 20px; }
  @media (max-width: 1199.98px) {
    .grid-tile .component-btn {
      margin-bottom: 1em; } }

@media (min-width: 992px) {
  .grid-tile .component-disclaimer {
    bottom: calc(0.3125rem + ((1vw - 9.92px) * 5.5804)); } }

@media screen and (min-width: 1440px) {
  .grid-tile .component-disclaimer {
    bottom: 1.875rem; } }

/* overriding the bootstrap header sizes */
.tab-layout .nav-item {
  min-width: 6.875rem;
  display: inline-block; }
  @media (min-width: 992px) {
    .tab-layout .nav-item {
      min-width: 12.5rem; } }

.tab-layout .nav-tabs {
  border-bottom: none;
  white-space: nowrap;
  overflow-x: auto;
  text-align: center;
  background: #efefef;
  border-radius: 0.25rem;
  width: fit-content; }
  .tab-layout .nav-tabs .nav-link {
    font-size: 0.875rem;
    background: #efefef;
    font-weight: 300; }
    .tab-layout .nav-tabs .nav-link.active {
      background: #fff;
      font-weight: 500;
      color: #000;
      border-radius: 0.25rem; }

/* overriding the bootstrap header sizes */
/* overriding the bootstrap header sizes */
strong {
  font-weight: 700; }

.veil {
  z-index: 1001; }

/* Works on Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #ccc #fff; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px; }

*::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px; }

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 8px; }

*::-webkit-scrollbar-thumb:hover {
  background: #555; }

body {
  color: #000; }
  body .font-hero {
    font-family: "ITC Garamond W01";
    font-size: 6rem;
    line-height: 6.5rem; }
    html[lang="ja"] body .font-hero {
      font-size: 4.5rem;
      line-height: 5rem; }
    @media (max-width: 991.98px) {
      body .font-hero {
        font-size: 3.5rem;
        line-height: 3.75rem; }
        html[lang="ja"] body .font-hero {
          font-size: 2.625rem;
          line-height: 2.875rem; } }
  body .didot-font {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.5rem;
    line-height: 2rem; }
    @media (min-width: 992px) {
      body .didot-font {
        font-family: "ITC Garamond W01";
        font-size: 3rem;
        line-height: 3.5rem; } }
  body .font-header .font-header-1, body .font-header .font-header-2, body .font-header .font-header-3 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: 0;
    font-weight: 500; }
  body .font-header-1 {
    font-size: 2rem;
    color: #000;
    line-height: 2.5rem; }
    html[lang="ja"] body .font-header-1 {
      font-size: 1.5rem;
      line-height: 2rem; }
    @media (max-width: 991.98px) {
      body .font-header-1--md {
        font-size: 1.5rem;
        line-height: 2rem; } }
  body .font-header-2 {
    font-size: 1.5rem;
    color: #000;
    line-height: 2rem; }
    html[lang="ja"] body .font-header-2 {
      font-size: 1.125rem;
      line-height: 1.625rem; }
    @media (max-width: 991.98px) {
      body .font-header-2.mobile-font-small {
        font-size: 0.875rem;
        line-height: 1rem; } }
  body .font-header-3 {
    font-size: 1rem;
    color: #000;
    line-height: 1.5rem; }
    @media (min-width: 769px) {
      body .font-header-3.desktop-subhead-2 {
        font-size: 0.875rem;
        line-height: 1rem; } }
  body .font-header-4 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: 0;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
    font-weight: 700; }
    @media (min-width: 769px) {
      body .font-header-4.desktop-subhead-2 {
        font-size: 0.875rem;
        line-height: 1rem; } }
  body .font-subheader-1 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 3rem;
    color: #000;
    letter-spacing: 0;
    line-height: 3.5rem; }
  body .font-subheader-2 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.25rem;
    color: #000;
    letter-spacing: 0;
    line-height: 2rem;
    font-weight: 500; }
  body .font-subheader-3 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1.5rem; }
  body .font-subheader-4 {
    color: #000;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1rem; }
  body .font-body {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1.5rem;
    font-weight: 300; }
    body .font-body-small {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.75rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.125rem; }
    body .font-body-large {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.5rem; }
      @media (max-width: 991.98px) {
        body .font-body-large.mobile-font-small {
          font-size: 0.875rem;
          line-height: 1.25rem; } }
    body .font-body-medium {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.25rem; }
  body .font-small {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.75rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1.125rem; }
  body .font-error {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    color: #cf0a2c;
    letter-spacing: 0;
    line-height: 1.5rem; }
  body .font-link {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-decoration: underline;
    font-size: 0.875rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1.25rem;
    font-weight: 500; }
    body .font-link.bold {
      font-weight: 700; }
    body .font-link.no-text-decoration {
      text-decoration: none; }
  body .bold {
    font-weight: 700; }
  body .normal {
    font-weight: 300; }
  body .regular {
    font-weight: 300; }
  body .font-weight-semibold {
    font-weight: 500; }
  body .text-underline {
    text-decoration: underline; }
  body .no-underline {
    text-decoration: none; }
  body .lowercase {
    text-transform: lowercase; }
  @media (min-width: 992px) {
    body .font-header-lg-1 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 2rem;
      color: #000;
      letter-spacing: 0;
      line-height: 2.5rem;
      font-weight: 500; }
    body .font-header-lg-2 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.5rem;
      color: #000;
      letter-spacing: 0;
      line-height: 2rem;
      font-weight: 700; }
    body .font-header-lg-3 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.5rem;
      font-weight: 500; } }
  @media (min-width: 992px) and (min-width: 769px) {
    body .font-header-lg-3.desktop-subhead-2 {
      font-size: 0.875rem;
      line-height: 1rem; } }
  @media (min-width: 992px) {
    body .font-subheader-lg-1 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 3rem;
      color: #000;
      letter-spacing: 0;
      line-height: 3.5rem; }
    body .font-subheader-lg-2 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.25rem;
      color: #000;
      letter-spacing: 0;
      line-height: 2rem;
      font-weight: 500; }
    body .font-subheader-lg-3 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.875rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1rem; }
    body .font-body-lg {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.875rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.25rem;
      font-weight: 300; }
      body .font-body-lg-small {
        font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 0.75rem;
        color: #000;
        letter-spacing: 0;
        line-height: 1rem; }
      body .font-body-lg-large {
        font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        color: #000;
        letter-spacing: 0;
        line-height: 1.5rem; } }
    @media (min-width: 992px) and (max-width: 991.98px) {
      body .font-body-lg-large.mobile-font-small {
        font-size: 0.875rem;
        line-height: 1.25rem; } }
  @media (min-width: 992px) {
      body .font-body-lg-medium {
        font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        color: #000;
        letter-spacing: 0;
        line-height: 1.25rem; }
    body .font-small-lg {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.75rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1rem; }
    body .font-error-lg {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.875rem;
      color: #cf0a2c;
      letter-spacing: 0;
      line-height: 1.5rem; }
    body .font-link-lg {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      text-decoration: underline;
      font-size: 0.875rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.25rem;
      font-weight: 500; }
      body .font-link-lg.bold {
        font-weight: 700; }
    body .bold-lg {
      font-weight: 700; }
    body .normal-lg {
      font-weight: 300; }
    body .regular-lg {
      font-weight: 300; }
    body .font-weight-semibold-lg {
      font-weight: 500; }
    body .text-underline-lg {
      text-decoration: underline; }
    body .no-underline-lg {
      text-decoration: none; }
    body .lowercase-lg {
      text-transform: lowercase; } }

.shop-the-look {
  background-color: white; }
  @media (min-width: 992px) {
    .shop-the-look {
      padding-left: 16px; }
      .shop-the-look h3 {
        font-size: 1.5rem; } }
  .shop-the-look .stylitics-desktop-modal {
    z-index: 100 !important; }
  .shop-the-look .stylitics-bundle-gallery {
    margin-bottom: 2.5rem !important; }

.stylitics-sequence::-webkit-scrollbar {
  display: none; }

.stylitics-item-container {
  padding-bottom: 25px; }

.stylitics-item-name {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600 !important;
  color: black !important;
  font-size: 1rem !important; }

.stylitics-view-item-details-cta {
  font-weight: 700; }

.stylitics-item-price-and-cta {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: black;
  padding-top: 12px; }

.stylitics-item-regular-price {
  font-size: 1rem !important; }

.stylitics-item-sale-price {
  font-size: 1rem !important; }

.stylitics-cta-link {
  font-size: 0.875rem !important; }

.stylitics-jump-link-container {
  right: 2%;
  z-index: 100;
  display: flex;
  background-color: rgba(21, 20, 21, 0.8); }
  .stylitics-jump-link-container .stylitics-jump-link {
    color: #fff;
    padding-right: 12px; }
    .stylitics-jump-link-container .stylitics-jump-link span {
      line-height: 14px;
      position: relative;
      font-size: 0.875rem; }
      @media (max-width: 991.98px) {
        .stylitics-jump-link-container .stylitics-jump-link span {
          line-height: 16px;
          font-size: 0.75rem; } }
    .stylitics-jump-link-container .stylitics-jump-link:hover {
      text-decoration: none !important; }

.full-gallery .stylitics-gallery-container {
  padding: 0 !important; }
  .full-gallery .stylitics-gallery-container .stylitics-bundle-gallery {
    padding: 0 !important; }
    .full-gallery .stylitics-gallery-container .stylitics-bundle-gallery .stylitics-card {
      margin-bottom: 2rem !important; }
      .full-gallery .stylitics-gallery-container .stylitics-bundle-gallery .stylitics-card .stylitics-bundle-container,
      .full-gallery .stylitics-gallery-container .stylitics-bundle-gallery .stylitics-card .stylitics-bundle-container img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; }
        .full-gallery .stylitics-gallery-container .stylitics-bundle-gallery .stylitics-card .stylitics-bundle-container .stylitics-footer-hide,
        .full-gallery .stylitics-gallery-container .stylitics-bundle-gallery .stylitics-card .stylitics-bundle-container img .stylitics-footer-hide {
          display: flex; }
      .full-gallery .stylitics-gallery-container .stylitics-bundle-gallery .stylitics-card .stylitics-product-list-click {
        position: static; }
      .full-gallery .stylitics-gallery-container .stylitics-bundle-gallery .stylitics-card .stylitics-view-item-details-cta {
        width: 101% !important;
        border-top: none !important;
        border: 1px solid #efefef !important;
        border-radius: 0 0 5px 5px !important;
        color: black !important; }
      .full-gallery .stylitics-gallery-container .stylitics-bundle-gallery .stylitics-card .stylitics-view-item-details-cta-gallery {
        max-width: none !important; }
      .full-gallery .stylitics-gallery-container .stylitics-bundle-gallery .stylitics-card .stylitics-bundle-details .stylitics-view-item-details-cta {
        transform: none !important;
        color: black !important; }

.full-gallery .stylitics-cta-link {
  text-transform: none !important;
  color: black !important; }

.gallery-single-card .stylitics-gallery-base {
  padding-bottom: 15px; }
  .gallery-single-card .stylitics-gallery-base .stylitics-gallery-container {
    padding: 0 !important; }
  .gallery-single-card .stylitics-gallery-base .stylitics-bundle-gallery {
    padding: 0 0 2.4rem !important;
    border: 1px solid #efefef !important;
    border-radius: 5px !important; }
    .gallery-single-card .stylitics-gallery-base .stylitics-bundle-gallery div.stylitics-card .stylitics-bundle-container {
      border-radius: 5px 5px 0 0;
      padding-bottom: 0;
      border: 0; }
      .gallery-single-card .stylitics-gallery-base .stylitics-bundle-gallery div.stylitics-card .stylitics-bundle-container .stylitics-main-image img {
        border-radius: 5px 5px 0 0; }
      .gallery-single-card .stylitics-gallery-base .stylitics-bundle-gallery div.stylitics-card .stylitics-bundle-container .stylitics-footer-hide {
        display: flex; }
      .gallery-single-card .stylitics-gallery-base .stylitics-bundle-gallery div.stylitics-card .stylitics-bundle-container .stylitics-bundle-footer {
        margin-top: 0; }
    .gallery-single-card .stylitics-gallery-base .stylitics-bundle-gallery div.stylitics-card .stylitics-product-list-click {
      width: 100%; }
      .gallery-single-card .stylitics-gallery-base .stylitics-bundle-gallery div.stylitics-card .stylitics-product-list-click .stylitics-view-item-details-cta-gallery {
        max-width: none; }
    .gallery-single-card .stylitics-gallery-base .stylitics-bundle-gallery div.stylitics-card button.stylitics-view-item-details-cta {
      width: 100% !important;
      border-top: 1px solid #efefef !important;
      border: 0;
      border-radius: 0;
      transform: none !important;
      padding-top: 7px; }
      @media (min-width: 992px) {
        .gallery-single-card .stylitics-gallery-base .stylitics-bundle-gallery div.stylitics-card button.stylitics-view-item-details-cta {
          padding-top: 5px; } }
      .gallery-single-card .stylitics-gallery-base .stylitics-bundle-gallery div.stylitics-card button.stylitics-view-item-details-cta span {
        text-transform: none !important;
        color: black !important; }

.gallery-single-card .stylitics-cta-link {
  text-transform: none !important;
  color: black !important; }

.shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-inner {
  padding-bottom: 1rem; }

.shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-bundle-container {
  padding: 0; }

.shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-arrow-btn {
  background-color: rgba(0, 0, 0, 0.6) !important;
  border-radius: 5px;
  height: 75px;
  top: 48% !important; }

.shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-next {
  right: 50px !important; }
  @media (max-width: 768.98px) {
    .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-next {
      right: 32px !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; } }

.shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-previous {
  left: 50px !important; }
  @media (max-width: 768.98px) {
    .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-previous {
      left: 32px !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; } }

.shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-arrow-btn svg path {
  stroke: white; }

.shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-card {
  margin-bottom: 2rem !important; }
  .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-card .stylitics-bundle-container,
  .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-card .stylitics-bundle-container img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-card .stylitics-bundle-container .stylitics-footer-hide,
    .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-card .stylitics-bundle-container img .stylitics-footer-hide {
      display: flex; }
  .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-card .stylitics-product-list-click {
    position: static; }
  .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-card .stylitics-view-item-details-cta {
    width: 101% !important;
    border-top: none !important;
    border: 1px solid #efefef !important;
    border-radius: 0 0 5px 5px !important;
    color: black !important; }
  .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-card .stylitics-view-item-details-cta-gallery {
    max-width: none !important; }
  .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-card .stylitics-bundle-details .stylitics-view-item-details-cta {
    transform: none !important;
    color: black !important; }

.shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-cta-link {
  text-transform: none !important;
  color: black !important; }

.shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-carousel-pips {
  padding-top: 0.5rem !important; }
  .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-carousel-pips .stylitics-dots-li {
    padding: 15px 0 !important;
    transform: none !important; }
    .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-carousel-pips .stylitics-dots-li .stylitics-dot-inner {
      width: 5vw !important;
      height: 5px !important;
      border-radius: unset !important; }
      @media (max-width: 991.98px) {
        .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-carousel-pips .stylitics-dots-li .stylitics-dot-inner {
          width: 8vw !important; } }
    .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-carousel-pips .stylitics-dots-li:first-child .stylitics-dot-inner {
      border-top-left-radius: 20px !important;
      border-bottom-left-radius: 20px !important; }
    .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-carousel-pips .stylitics-dots-li:last-child .stylitics-dot-inner {
      border-top-right-radius: 20px !important;
      border-bottom-right-radius: 20px !important; }

.shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-sequence {
  padding-left: 1px !important;
  padding-right: 1px !important;
  scrollbar-width: none !important; }
  @media (max-width: 991.98px) {
    .shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-sequence {
      padding-bottom: 0 !important; } }

.shop-the-look-gallery-carousel div[id^='stylitics-gallery-carousel-widget-container-'] .stylitics-bundle-footer {
  text-align: center !important; }

div[id^="stylitics-container-"][data-locale$=AT] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=AT] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=BE] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=BE] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=DE] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=DE] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=DK] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=DK] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=ES] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=ES] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=FI] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=FI] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=FR] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=FR] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=GB] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=GB] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=IE] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=IE] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=IT] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=IT] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=LT] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=LT] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=LU] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=LU] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=LV] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=LV] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=NO] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=NO] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=NL] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=NL] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=PT] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=PT] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=SE] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=SE] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=SI] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=SI] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-container-"][data-locale$=ZA] .stylitics-ui-item-info-price,
div[id^="stylitics-container-"][data-locale$=ZA] .stylitics-ui-price {
  display: none; }

div[id^="stylitics-widget-container-"] img.stylitics-ui-collage-item-image,
div[id^="stylitics-widget-container-"] img.stylitics-image {
  -webkit-filter: drop-shadow(2.5px 2.5px 8px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(2.5px 2.5px 8px rgba(0, 0, 0, 0.3)) !important; }

div[id^="stylitics-widget-container-"] .stylitics-dimension-collage-card {
  background: #f6f6f6; }

/* overriding the bootstrap header sizes */
.experience-commerce_assets-videoComponent video.frame-height {
  height: auto; }
  @media (min-width: 992px) {
    .experience-commerce_assets-videoComponent video.frame-height {
      height: calc(100vh - 140px); } }

.experience-commerce_assets-videoComponent .frame-height {
  max-height: 22.5rem; }
  @media (min-width: 992px) {
    .experience-commerce_assets-videoComponent .frame-height {
      max-height: 42.5rem; } }

.nb-pd-video {
  position: relative;
  width: 100%; }
  .nb-pd-video .video-controls {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    align-items: flex-end;
    border-radius: 5px;
    z-index: 10; }
    @media (max-width: 991.98px) {
      .nb-pd-video .video-controls {
        top: 15px;
        right: 10px; } }
    .nb-pd-video .video-controls .play-pause {
      background: transparent;
      padding: 5px;
      cursor: pointer;
      margin-left: 3px; }
      .nb-pd-video .video-controls .play-pause img {
        left: 62px;
        border-radius: 5px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.7);
        height: 40px;
        padding: 5px;
        position: static; }
        @media (max-width: 991.98px) {
          .nb-pd-video .video-controls .play-pause img {
            height: 24px;
            left: 42px; } }
      .nb-pd-video .video-controls .play-pause .btn-pause {
        display: none; }
      .nb-pd-video .video-controls .play-pause .btn-play {
        display: inline-block; }
      .nb-pd-video .video-controls .play-pause.paused .btn-pause {
        display: inline-block; }
      .nb-pd-video .video-controls .play-pause.paused .btn-play {
        display: none; }
    .nb-pd-video .video-controls .volume {
      background: transparent;
      padding: 5px;
      cursor: pointer;
      padding-right: 0 !important; }
      .nb-pd-video .video-controls .volume img {
        left: 10px;
        border-radius: 5px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.7);
        height: 40px;
        padding: 5px;
        position: static; }
        @media (max-width: 991.98px) {
          .nb-pd-video .video-controls .volume img {
            height: 24px; } }
      .nb-pd-video .video-controls .volume .btn-mute {
        display: none; }
      .nb-pd-video .video-controls .volume .btn-unmute {
        display: inline-block; }
      .nb-pd-video .video-controls .volume.muted .btn-mute {
        display: inline-block; }
      .nb-pd-video .video-controls .volume.muted .btn-unmute {
        display: none; }
  .nb-pd-video video::-webkit-media-controls-play-button,
  .nb-pd-video video::-webkit-media-controls-volume-control-container,
  .nb-pd-video video::-webkit-media-controls-fullscreen-button {
    display: none; }

/* overriding the bootstrap header sizes */
@media (min-width: 1440px) {
  .visual-navigation .mx-lg-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; } }

.visual-navigation .slick-dots {
  bottom: 0 !important; }

/* overriding the bootstrap header sizes */
.two-column-text {
  margin: auto; }
  @media (min-width: 992px) {
    .two-column-text .description {
      column-count: 2;
      column-gap: 5.5rem; } }

/* overriding the bootstrap header sizes */
.text-component-container h1,
.text-component-container h2,
.text-component-container h3,
.text-component-container h4,
.text-component-container p,
.text-component-container li,
.text-component-container a {
  color: #000;
  letter-spacing: 0; }

.text-component-container.text-component-white h1,
.text-component-container.text-component-white h2,
.text-component-container.text-component-white h3,
.text-component-container.text-component-white h4,
.text-component-container.text-component-white p,
.text-component-container.text-component-white li,
.text-component-container.text-component-white a {
  color: #fff !important; }

.text-component-container h1 {
  font-family: "ITC Garamond W01";
  font-size: 3.5rem;
  line-height: 3.75rem; }

.text-component-container h2 {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem; }

.text-component-container h3 {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem; }

.text-component-container h4 {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1rem; }

.text-component-container p,
.text-component-container li {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem; }

.text-component-container a {
  text-decoration: underline; }

.text-component-container ul {
  list-style: disc inside; }

.text-component-container ol {
  list-style: decimal inside; }

.text-component-container.text-component-red-line h1::after,
.text-component-container.text-component-red-line h2::after {
  background-color: #cf0a2c;
  content: '';
  display: block;
  height: 0.25rem;
  position: relative;
  width: 5rem; }

.text-component-container.text-component-red-line h1 {
  margin-bottom: 1.5rem; }
  .text-component-container.text-component-red-line h1::after {
    margin-top: 1.5rem; }

.text-component-container.text-component-red-line h2 {
  margin-bottom: 1rem; }
  .text-component-container.text-component-red-line h2::after {
    margin-top: 0.75rem; }

.text-component-container.text-component-red-line.text-center h1::after,
.text-component-container.text-component-red-line.text-center h2::after {
  margin-left: auto;
  margin-right: auto; }

.text-component-container.icon-text-center {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important; }

.text-component-container .disclaimer-pgp h1,
.text-component-container .disclaimer-pgp h2,
.text-component-container .disclaimer-pgp h3,
.text-component-container .disclaimer-pgp h4,
.text-component-container .disclaimer-pgp p,
.text-component-container .disclaimer-pgp li,
.text-component-container .disclaimer-pgp a {
  color: #5c6268 !important;
  font-size: 0.75rem;
  line-height: 1.125rem; }

@media (min-width: 992px) {
  .text-component-container .icon-text-w-50 {
    width: 50%; }
  .text-component-container .icon-text-w-75 {
    width: 75%; } }

.whitespace-size-1 {
  height: 1px; }

.whitespace-size-2 {
  height: 2px; }

.whitespace-size-3 {
  height: 4px; }

.whitespace-size-4 {
  height: 8px; }

.whitespace-size-5 {
  height: 16px; }

.whitespace-size-6 {
  height: 32px; }

.whitespace-size-7 {
  height: 48px; }

.whitespace-size-8 {
  height: 96px; }

.whitespace-bg-white {
  background-color: #fff; }

.whitespace-bg-black {
  background-color: #000; }

.whitespace-bg-nb-black {
  background-color: #151415; }

.whitespace-bg-grey-1 {
  background-color: #f6f6f6; }

.whitespace-bg-grey-2 {
  background-color: #efefef; }

/* overriding the bootstrap header sizes */
.table-component td {
  font-weight: 300; }
  .table-component td a {
    text-decoration: underline; }

.table-component tr:first-child th,
.table-component tr:first-child td {
  font-weight: 700; }

.table-component.cc-size-guide-table table {
  table-layout: fixed;
  text-align: center; }

.table-component.cc-size-guide-table tr:hover, .table-component.cc-size-guide-table tr:focus {
  background-color: #585858;
  color: #fff; }
  .table-component.cc-size-guide-table tr:hover h1,
  .table-component.cc-size-guide-table tr:hover h2,
  .table-component.cc-size-guide-table tr:hover h3,
  .table-component.cc-size-guide-table tr:hover h4,
  .table-component.cc-size-guide-table tr:hover a, .table-component.cc-size-guide-table tr:focus h1,
  .table-component.cc-size-guide-table tr:focus h2,
  .table-component.cc-size-guide-table tr:focus h3,
  .table-component.cc-size-guide-table tr:focus h4,
  .table-component.cc-size-guide-table tr:focus a {
    color: #fff; }

.table-component.cc-size-guide-table tr:first-child:hover, .table-component.cc-size-guide-table tr:first-child:focus {
  background-color: rgba(0, 0, 0, 0.05);
  color: inherit; }
  .table-component.cc-size-guide-table tr:first-child:hover h1,
  .table-component.cc-size-guide-table tr:first-child:hover h2,
  .table-component.cc-size-guide-table tr:first-child:hover h3,
  .table-component.cc-size-guide-table tr:first-child:hover h4,
  .table-component.cc-size-guide-table tr:first-child:hover a, .table-component.cc-size-guide-table tr:first-child:focus h1,
  .table-component.cc-size-guide-table tr:first-child:focus h2,
  .table-component.cc-size-guide-table tr:first-child:focus h3,
  .table-component.cc-size-guide-table tr:first-child:focus h4,
  .table-component.cc-size-guide-table tr:first-child:focus a {
    color: inherit; }

.table-component.cc-size-guide-table td,
.table-component.cc-size-guide-table th {
  border-right: 3px solid #fff;
  border-top: none;
  padding-left: 2px;
  padding-right: 2px;
  vertical-align: middle; }

.table-component.cc-size-guide-table tr:first-child th,
.table-component.cc-size-guide-table tr:first-child td {
  color: #585858;
  font-size: 0.75rem;
  font-weight: 300; }
  .table-component.cc-size-guide-table tr:first-child th h1,
  .table-component.cc-size-guide-table tr:first-child th h2,
  .table-component.cc-size-guide-table tr:first-child th h3,
  .table-component.cc-size-guide-table tr:first-child th h4,
  .table-component.cc-size-guide-table tr:first-child td h1,
  .table-component.cc-size-guide-table tr:first-child td h2,
  .table-component.cc-size-guide-table tr:first-child td h3,
  .table-component.cc-size-guide-table tr:first-child td h4 {
    color: #585858; }
  @media (max-width: 768.98px) {
    .table-component.cc-size-guide-table tr:first-child th,
    .table-component.cc-size-guide-table tr:first-child td {
      padding-top: 20px;
      padding-bottom: 20px; } }
  @media (min-width: 769px) {
    .table-component.cc-size-guide-table tr:first-child th,
    .table-component.cc-size-guide-table tr:first-child td {
      font-size: 21px; } }

.table-component.cc-size-guide-table tr:nth-child(n+2) td {
  font-size: 0.875rem; }
  .table-component.cc-size-guide-table tr:nth-child(n+2) td:hover, .table-component.cc-size-guide-table tr:nth-child(n+2) td:focus {
    background-color: #cf0a2c;
    color: #fff; }

.table-component.legacy-pdp-size-chart-table table {
  border: 2px solid #ccc;
  text-align: center; }
  .table-component.legacy-pdp-size-chart-table table tr:hover, .table-component.legacy-pdp-size-chart-table table tr:focus {
    background-color: #cf0a2c;
    color: #fff; }
    .table-component.legacy-pdp-size-chart-table table tr:hover h1,
    .table-component.legacy-pdp-size-chart-table table tr:hover h2,
    .table-component.legacy-pdp-size-chart-table table tr:hover h3,
    .table-component.legacy-pdp-size-chart-table table tr:hover h4,
    .table-component.legacy-pdp-size-chart-table table tr:hover a, .table-component.legacy-pdp-size-chart-table table tr:focus h1,
    .table-component.legacy-pdp-size-chart-table table tr:focus h2,
    .table-component.legacy-pdp-size-chart-table table tr:focus h3,
    .table-component.legacy-pdp-size-chart-table table tr:focus h4,
    .table-component.legacy-pdp-size-chart-table table tr:focus a {
      color: #fff; }
  .table-component.legacy-pdp-size-chart-table table tr:first-child:hover, .table-component.legacy-pdp-size-chart-table table tr:first-child:focus {
    background-color: #f2f2f2;
    color: inherit; }
    .table-component.legacy-pdp-size-chart-table table tr:first-child:hover h1,
    .table-component.legacy-pdp-size-chart-table table tr:first-child:hover h2,
    .table-component.legacy-pdp-size-chart-table table tr:first-child:hover h3,
    .table-component.legacy-pdp-size-chart-table table tr:first-child:hover h4,
    .table-component.legacy-pdp-size-chart-table table tr:first-child:hover a, .table-component.legacy-pdp-size-chart-table table tr:first-child:focus h1,
    .table-component.legacy-pdp-size-chart-table table tr:first-child:focus h2,
    .table-component.legacy-pdp-size-chart-table table tr:first-child:focus h3,
    .table-component.legacy-pdp-size-chart-table table tr:first-child:focus h4,
    .table-component.legacy-pdp-size-chart-table table tr:first-child:focus a {
      color: inherit; }
  .table-component.legacy-pdp-size-chart-table table th,
  .table-component.legacy-pdp-size-chart-table table td {
    vertical-align: middle; }

.table-component.pdp-size-chart-table table {
  border: 1px solid #ccc;
  text-align: center;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 3px;
  margin-bottom: 0.5rem; }
  .table-component.pdp-size-chart-table table .table-header th {
    padding-left: 7px;
    border-top: none; }
  .table-component.pdp-size-chart-table table .table-header + tr {
    background-color: #efefef !important; }
  .table-component.pdp-size-chart-table table tr {
    text-align: left;
    padding-left: 7px; }
    .table-component.pdp-size-chart-table table tr:first-child {
      background-color: #efefef !important; }
      .table-component.pdp-size-chart-table table tr:first-child td:first-child {
        background-color: unset !important; }
      .table-component.pdp-size-chart-table table tr:first-child td {
        border-top: none; }
    .table-component.pdp-size-chart-table table tr:nth-of-type(odd) {
      background-color: unset; }
  .table-component.pdp-size-chart-table table th,
  .table-component.pdp-size-chart-table table td {
    width: 21%;
    padding-left: 7px;
    vertical-align: middle;
    border-right: 1px solid #ccc; }
    .table-component.pdp-size-chart-table table th:first-child,
    .table-component.pdp-size-chart-table table td:first-child {
      background-color: #efefef !important; }
    .table-component.pdp-size-chart-table table th:last-child,
    .table-component.pdp-size-chart-table table td:last-child {
      border-right: none; }
    .table-component.pdp-size-chart-table table th:nth-child(0),
    .table-component.pdp-size-chart-table table td:nth-child(0) {
      background-color: unset; }

.table-component.pdp-size-chart-table.table-responsive {
  width: 427px;
  padding: 0;
  margin: 0; }

.experience-commerce_assets-TableComponent {
  overflow-x: auto; }

.table-component.pdp-size-chart-table-customizable table {
  min-width: 472px;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 3px;
  margin-bottom: 0.5rem; }
  .table-component.pdp-size-chart-table-customizable table th,
  .table-component.pdp-size-chart-table-customizable table td {
    border: 1px solid #ccc;
    width: 20px;
    vertical-align: middle;
    text-align: center;
    font-weight: 300; }
  .table-component.pdp-size-chart-table-customizable table .spacer-row {
    height: 5px !important;
    padding: 0;
    border: none !important;
    background-color: unset; }
  .table-component.pdp-size-chart-table-customizable table .spacer-column {
    width: 0.75% !important;
    padding: 0;
    border: none !important;
    background-color: unset; }
    @media (max-width: 768.98px) {
      .table-component.pdp-size-chart-table-customizable table .spacer-column {
        width: 0.5% !important; } }
    @media (max-width: 543.98px) {
      .table-component.pdp-size-chart-table-customizable table .spacer-column {
        width: 0.75% !important; } }

.table-component.pdp-size-chart-table-customizable.table-responsive {
  padding: 0;
  margin: 0;
  overflow-x: visible; }

.table-component.shipping-table tr:first-child th,
.table-component.shipping-table tr:first-child td {
  border-bottom: 2px solid #ccc;
  vertical-align: bottom; }

.table-component h1 {
  font-family: "ITC Garamond W01";
  font-size: 3.5rem;
  line-height: 3.75rem; }
  @media (min-width: 769px) {
    .table-component h1 {
      font-size: 3rem;
      line-height: 3.5rem; } }

.table-component h2 {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem; }

.table-component h3 {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem; }

.table-component h4 {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1rem; }

.table-component p,
.table-component li {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300; }

.table-component b {
  font-weight: 700; }

.table-component a {
  text-decoration: underline; }

.table-component ul {
  list-style: disc inside; }

.table-component ol {
  list-style: decimal inside; }

.table-component h1,
.table-component h2,
.table-component h3,
.table-component h4,
.table-component p,
.table-component li,
.table-component a {
  margin-bottom: 0; }

.table-component [align="left"] {
  text-align: left !important; }

.table-component [align="center"] {
  text-align: center !important; }

.table-component [align="right"] {
  text-align: right !important; }

.table-component .spacer-row {
  height: 2%;
  padding: 0;
  border: none;
  background-color: unset; }

.table-component .spacer-column {
  width: 2%;
  padding: 0;
  border: none;
  background-color: unset; }

.table-component .sticky-row {
  position: sticky;
  top: 0; }

.table-component .sticky-column {
  position: sticky;
  left: 0; }

li.search-terms-suggestions div.storepage {
  width: 100%; }

/* overriding the bootstrap header sizes */
/* overriding the bootstrap header sizes */
strong {
  font-weight: 700; }

.veil {
  z-index: 1001; }

/* Works on Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #ccc #fff; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px; }

*::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px; }

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 8px; }

*::-webkit-scrollbar-thumb:hover {
  background: #555; }

body {
  color: #000; }
  body .font-hero {
    font-family: "ITC Garamond W01";
    font-size: 6rem;
    line-height: 6.5rem; }
    html[lang="ja"] body .font-hero {
      font-size: 4.5rem;
      line-height: 5rem; }
    @media (max-width: 991.98px) {
      body .font-hero {
        font-size: 3.5rem;
        line-height: 3.75rem; }
        html[lang="ja"] body .font-hero {
          font-size: 2.625rem;
          line-height: 2.875rem; } }
  body .didot-font {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.5rem;
    line-height: 2rem; }
    @media (min-width: 992px) {
      body .didot-font {
        font-family: "ITC Garamond W01";
        font-size: 3rem;
        line-height: 3.5rem; } }
  body .font-header .font-header-1, body .font-header .font-header-2, body .font-header .font-header-3 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: 0;
    font-weight: 500; }
  body .font-header-1 {
    font-size: 2rem;
    color: #000;
    line-height: 2.5rem; }
    html[lang="ja"] body .font-header-1 {
      font-size: 1.5rem;
      line-height: 2rem; }
    @media (max-width: 991.98px) {
      body .font-header-1--md {
        font-size: 1.5rem;
        line-height: 2rem; } }
  body .font-header-2 {
    font-size: 1.5rem;
    color: #000;
    line-height: 2rem; }
    html[lang="ja"] body .font-header-2 {
      font-size: 1.125rem;
      line-height: 1.625rem; }
    @media (max-width: 991.98px) {
      body .font-header-2.mobile-font-small {
        font-size: 0.875rem;
        line-height: 1rem; } }
  body .font-header-3 {
    font-size: 1rem;
    color: #000;
    line-height: 1.5rem; }
    @media (min-width: 769px) {
      body .font-header-3.desktop-subhead-2 {
        font-size: 0.875rem;
        line-height: 1rem; } }
  body .font-header-4 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: 0;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
    font-weight: 700; }
    @media (min-width: 769px) {
      body .font-header-4.desktop-subhead-2 {
        font-size: 0.875rem;
        line-height: 1rem; } }
  body .font-subheader-1 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 3rem;
    color: #000;
    letter-spacing: 0;
    line-height: 3.5rem; }
  body .font-subheader-2 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.25rem;
    color: #000;
    letter-spacing: 0;
    line-height: 2rem;
    font-weight: 500; }
  body .font-subheader-3 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1.5rem; }
  body .font-subheader-4 {
    color: #000;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1rem; }
  body .font-body {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1.5rem;
    font-weight: 300; }
    body .font-body-small {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.75rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.125rem; }
    body .font-body-large {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.5rem; }
      @media (max-width: 991.98px) {
        body .font-body-large.mobile-font-small {
          font-size: 0.875rem;
          line-height: 1.25rem; } }
    body .font-body-medium {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.25rem; }
  body .font-small {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.75rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1.125rem; }
  body .font-error {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    color: #cf0a2c;
    letter-spacing: 0;
    line-height: 1.5rem; }
  body .font-link {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-decoration: underline;
    font-size: 0.875rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1.25rem;
    font-weight: 500; }
    body .font-link.bold {
      font-weight: 700; }
    body .font-link.no-text-decoration {
      text-decoration: none; }
  body .bold {
    font-weight: 700; }
  body .normal {
    font-weight: 300; }
  body .regular {
    font-weight: 300; }
  body .font-weight-semibold {
    font-weight: 500; }
  body .text-underline {
    text-decoration: underline; }
  body .no-underline {
    text-decoration: none; }
  body .lowercase {
    text-transform: lowercase; }
  @media (min-width: 992px) {
    body .font-header-lg-1 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 2rem;
      color: #000;
      letter-spacing: 0;
      line-height: 2.5rem;
      font-weight: 500; }
    body .font-header-lg-2 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.5rem;
      color: #000;
      letter-spacing: 0;
      line-height: 2rem;
      font-weight: 700; }
    body .font-header-lg-3 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.5rem;
      font-weight: 500; } }
  @media (min-width: 992px) and (min-width: 769px) {
    body .font-header-lg-3.desktop-subhead-2 {
      font-size: 0.875rem;
      line-height: 1rem; } }
  @media (min-width: 992px) {
    body .font-subheader-lg-1 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 3rem;
      color: #000;
      letter-spacing: 0;
      line-height: 3.5rem; }
    body .font-subheader-lg-2 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.25rem;
      color: #000;
      letter-spacing: 0;
      line-height: 2rem;
      font-weight: 500; }
    body .font-subheader-lg-3 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.875rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1rem; }
    body .font-body-lg {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.875rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.25rem;
      font-weight: 300; }
      body .font-body-lg-small {
        font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 0.75rem;
        color: #000;
        letter-spacing: 0;
        line-height: 1rem; }
      body .font-body-lg-large {
        font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        color: #000;
        letter-spacing: 0;
        line-height: 1.5rem; } }
    @media (min-width: 992px) and (max-width: 991.98px) {
      body .font-body-lg-large.mobile-font-small {
        font-size: 0.875rem;
        line-height: 1.25rem; } }
  @media (min-width: 992px) {
      body .font-body-lg-medium {
        font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        color: #000;
        letter-spacing: 0;
        line-height: 1.25rem; }
    body .font-small-lg {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.75rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1rem; }
    body .font-error-lg {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.875rem;
      color: #cf0a2c;
      letter-spacing: 0;
      line-height: 1.5rem; }
    body .font-link-lg {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      text-decoration: underline;
      font-size: 0.875rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.25rem;
      font-weight: 500; }
      body .font-link-lg.bold {
        font-weight: 700; }
    body .bold-lg {
      font-weight: 700; }
    body .normal-lg {
      font-weight: 300; }
    body .regular-lg {
      font-weight: 300; }
    body .font-weight-semibold-lg {
      font-weight: 500; }
    body .text-underline-lg {
      text-decoration: underline; }
    body .no-underline-lg {
      text-decoration: none; }
    body .lowercase-lg {
      text-transform: lowercase; } }

.product-comparison-title {
  margin-bottom: 2rem;
  margin-top: 4rem; }
  .product-comparison-title h2 {
    font-size: 3rem; }
  .product-comparison-title a {
    font-size: 1rem; }

.product-comparison-container {
  margin-top: 2em;
  margin-bottom: 2em !important; }
  .product-comparison-container .product-detail-select {
    position: relative; }
    .product-comparison-container .product-detail-select::after {
      content: "";
      pointer-events: none;
      position: absolute;
      background-image: url(../../images/down-icon.svg);
      background-size: cover;
      top: 50%;
      right: 15px;
      height: 15px;
      width: 15px;
      transform: translateY(calc(-50% - 5px)); }

.product-comparison-select {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 10px;
  column-gap: 20px;
  min-width: 25%; }

.comparison-select {
  display: block;
  width: 100%;
  border-color: #d8d8d8;
  border-radius: 0.5rem;
  padding: 15px 33px 15px 10px;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  max-width: calc(100% + 0);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 15px; }
  .comparison-select option {
    background-color: #efefef; }

.product-comparison-detail {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 10px;
  column-gap: 20px;
  min-height: 600px; }
  .product-comparison-detail .product-detail-item {
    border-style: dashed;
    border-color: #d8d8d8;
    border-radius: 0.5rem;
    vertical-align: middle;
    position: relative;
    border-width: 2px;
    min-width: 25%; }
    .product-comparison-detail .product-detail-item.no-dash {
      border-style: none; }
    .product-comparison-detail .product-detail-item .empty-panel {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      color: #737b82;
      font-size: 1rem;
      text-align: center;
      line-height: 1; }
  .product-comparison-detail .product-bestfor {
    font-size: medium;
    font-weight: 700;
    padding: 0 10px 15px;
    text-align: center;
    width: 100%;
    min-height: 4rem; }
    .product-comparison-detail .product-bestfor .headline-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px; }
  .product-comparison-detail .tile-image {
    width: 100%;
    padding: 0 0 15px;
    object-fit: contain; }
    .product-comparison-detail .tile-image::after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff; }
  .product-comparison-detail .product-pill-container .pill-header {
    text-align: center;
    margin-bottom: 0.3rem;
    font-size: 1rem; }
  .product-comparison-detail .product-pill-container .pills {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5px; }
    .product-comparison-detail .product-pill-container .pills .pill {
      height: 10px;
      background-color: #fff;
      border: #000 solid 1px;
      color: #fff;
      border-radius: 50px; }
      .product-comparison-detail .product-pill-container .pills .pill.filled {
        background-color: #000; }
  .product-comparison-detail .comparison-tile .product-technology,
  .product-comparison-detail .comparison-tile .product-drop,
  .product-comparison-detail .comparison-tile .product-weight {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    padding: 15px 10px 15px; }
    .product-comparison-detail .comparison-tile .product-technology .tech-img,
    .product-comparison-detail .comparison-tile .product-drop .tech-img,
    .product-comparison-detail .comparison-tile .product-weight .tech-img {
      height: 2.5rem;
      max-width: 60%;
      min-width: 5rem;
      margin: -15px -5px; }
    .product-comparison-detail .comparison-tile .product-technology .info-svg,
    .product-comparison-detail .comparison-tile .product-drop .info-svg,
    .product-comparison-detail .comparison-tile .product-weight .info-svg {
      height: 1.2rem;
      border: 1px solid #000;
      border-radius: 1rem;
      width: 1.2rem;
      padding: 2px 4px;
      margin-left: 10px; }
    .product-comparison-detail .comparison-tile .product-technology .left-panel p,
    .product-comparison-detail .comparison-tile .product-drop .left-panel p,
    .product-comparison-detail .comparison-tile .product-weight .left-panel p {
      margin: 0;
      font-size: 1rem; }
    .product-comparison-detail .comparison-tile .product-technology .right-panel,
    .product-comparison-detail .comparison-tile .product-drop .right-panel,
    .product-comparison-detail .comparison-tile .product-weight .right-panel {
      position: relative;
      text-align: right;
      white-space: nowrap; }
  .product-comparison-detail .comparison-tile .product-bestfor p,
  .product-comparison-detail .comparison-tile .product-technology p,
  .product-comparison-detail .comparison-tile .product-drop p,
  .product-comparison-detail .comparison-tile .product-weight p {
    margin: 0; }
  .product-comparison-detail .comparison-tile .product-technology .right-panel {
    white-space: nowrap; }
    .product-comparison-detail .comparison-tile .product-technology .right-panel .empty-section:hover {
      cursor: pointer; }
    .product-comparison-detail .comparison-tile .product-technology .right-panel:hover {
      cursor: pointer; }
    .product-comparison-detail .comparison-tile .product-technology .right-panel .t-tip {
      position: absolute;
      width: 225px;
      height: 120px;
      background-color: #fff;
      border: 1px solid #d8d8d8;
      padding: 5px 15px;
      text-align: center;
      font-size: 0.8rem;
      display: none;
      align-items: center;
      justify-content: center;
      top: -125px;
      right: -20px;
      border-radius: 5px;
      white-space: normal; }
      .product-comparison-detail .comparison-tile .product-technology .right-panel .t-tip p {
        margin: 0; }
      .product-comparison-detail .comparison-tile .product-technology .right-panel .t-tip::after {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        bottom: -7px;
        right: 23px;
        border: 1px solid #d8d8d8;
        transform: rotate(45deg);
        background-color: #fff;
        border-top: none;
        border-left: none; }
  .product-comparison-detail .product-cushion,
  .product-comparison-detail .product-technology,
  .product-comparison-detail .product-drop,
  .product-comparison-detail .product-weight {
    border-bottom: 1px solid #d8d8d8; }
  .product-comparison-detail .product-price {
    padding: 15px 10px 15px; }
  .product-comparison-detail .product-shop-now a {
    width: 60%;
    border-radius: 5px;
    height: unset; }
  .product-comparison-detail .empty-section {
    display: grid;
    column-gap: 20px;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    height: 1.5rem; }
    .product-comparison-detail .empty-section .no-data {
      height: 2px;
      border: #d8d8d8 solid 1px; }
  .product-comparison-detail .product-stability,
  .product-comparison-detail .product-cushion {
    padding: 0 10px 15px; }
    .product-comparison-detail .product-stability .empty-section,
    .product-comparison-detail .product-cushion .empty-section {
      height: calc(2rem + 7px); }

@media (max-width: 1199.98px) {
  .comparison-tile .product-technology .empty-section,
  .comparison-tile .product-drop .empty-section,
  .comparison-tile .product-weight .empty-section {
    column-gap: 5px; } }

@media (max-width: 991.98px) {
  .product-comparison-title h2 {
    font-size: 2rem; }
  .product-comparison-select,
  .product-comparison-detail {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px; }
    .product-comparison-select .product-item-3,
    .product-comparison-detail .product-item-3 {
      display: none; }
    .product-comparison-select .product-item-4,
    .product-comparison-detail .product-item-4 {
      display: none; }
    .product-comparison-select .product-stability .empty-section,
    .product-comparison-select .product-cushion .empty-section,
    .product-comparison-detail .product-stability .empty-section,
    .product-comparison-detail .product-cushion .empty-section {
      height: 2.4rem; }
    .product-comparison-select .product-technology .empty-section,
    .product-comparison-select .product-drop .empty-section,
    .product-comparison-select .product-weight .empty-section,
    .product-comparison-detail .product-technology .empty-section,
    .product-comparison-detail .product-drop .empty-section,
    .product-comparison-detail .product-weight .empty-section {
      height: 1.2rem; } }

@media (max-width: 543.98px) {
  .product-comparison-detail .product-stability,
  .product-comparison-detail .product-cushion {
    text-align: center; }
    .product-comparison-detail .product-stability .empty-section,
    .product-comparison-detail .product-cushion .empty-section {
      height: calc(2rem + 2px); }
  .product-comparison-detail .comparison-tile .product-pill-container .pill-header {
    font-size: 0.8rem; }
  .product-comparison-detail .comparison-tile .product-bestfor {
    padding: 10px 5px !important; }
  .product-comparison-detail .comparison-tile .product-bestfor,
  .product-comparison-detail .comparison-tile .product-technology,
  .product-comparison-detail .comparison-tile .product-drop,
  .product-comparison-detail .comparison-tile .product-weight {
    column-gap: 0;
    padding: 15px 5px;
    font-size: 0.8rem; }
    .product-comparison-detail .comparison-tile .product-bestfor .left-panel p,
    .product-comparison-detail .comparison-tile .product-bestfor .right-panel p,
    .product-comparison-detail .comparison-tile .product-technology .left-panel p,
    .product-comparison-detail .comparison-tile .product-technology .right-panel p,
    .product-comparison-detail .comparison-tile .product-drop .left-panel p,
    .product-comparison-detail .comparison-tile .product-drop .right-panel p,
    .product-comparison-detail .comparison-tile .product-weight .left-panel p,
    .product-comparison-detail .comparison-tile .product-weight .right-panel p {
      font-size: 0.8rem; }
    .product-comparison-detail .comparison-tile .product-bestfor .right-panel .t-tip,
    .product-comparison-detail .comparison-tile .product-technology .right-panel .t-tip,
    .product-comparison-detail .comparison-tile .product-drop .right-panel .t-tip,
    .product-comparison-detail .comparison-tile .product-weight .right-panel .t-tip {
      width: 165px;
      z-index: 1;
      right: -4px; }
      .product-comparison-detail .comparison-tile .product-bestfor .right-panel .t-tip::after,
      .product-comparison-detail .comparison-tile .product-technology .right-panel .t-tip::after,
      .product-comparison-detail .comparison-tile .product-drop .right-panel .t-tip::after,
      .product-comparison-detail .comparison-tile .product-weight .right-panel .t-tip::after {
        right: 4px; }
    .product-comparison-detail .comparison-tile .product-bestfor .empty-section,
    .product-comparison-detail .comparison-tile .product-technology .empty-section,
    .product-comparison-detail .comparison-tile .product-drop .empty-section,
    .product-comparison-detail .comparison-tile .product-weight .empty-section {
      height: 1.2rem; }
  .product-comparison-detail .comparison-tile .product-weight {
    grid-template-columns: 1fr  4fr; }
  .product-comparison-detail .comparison-tile .product-technology .tech-img {
    min-width: 4.5rem; }
  .product-comparison-detail .comparison-tile .product-technology .info-svg {
    height: 0.9rem;
    width: 0.9rem;
    margin-left: 0; } }

.guided-buying {
  column-gap: 1rem; }
  @media (min-width: 992px) {
    .guided-buying {
      column-gap: 0.5rem; } }
  .guided-buying .gb-tile {
    flex-grow: 1;
    flex-basis: 100%; }
    .guided-buying .gb-tile .tile-img {
      object-fit: cover;
      max-height: 250px;
      position: relative;
      border-radius: 8px; }
      @media (min-width: 992px) {
        .guided-buying .gb-tile .tile-img {
          max-height: 450px; } }
    .guided-buying .gb-tile .arrow-right {
      display: inline-block;
      width: 14px; }
    @media (min-width: 992px) {
      .guided-buying .gb-tile .gb-tile-text-block.two-tiles {
        width: 50%; } }
    @media (min-width: 992px) {
      .guided-buying .gb-tile .gb-tile-text-block.three-tiles {
        width: 70%; } }
    .guided-buying .gb-tile .gb-tile-text-block .link-text {
      overflow: hidden;
      position: relative;
      --lh: 1.4rem;
      --max-lines: 2;
      max-height: calc(var(--lh) * var(--max-lines)); }
    .guided-buying .gb-tile .gb-tile-text-block .link-text::after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: -13px;
      right: 0;
      width: 100%;
      height: 1.2em;
      background: white; }
    .guided-buying .gb-tile .gb-tile-text-block .link-subtext {
      overflow: hidden;
      position: relative;
      --lh: 1.4rem;
      --max-lines: 2;
      max-height: calc(var(--lh) * var(--max-lines)); }
  .guided-buying .slick-dots {
    position: absolute;
    bottom: 14px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    border-radius: 0;
    background: unset; }
    .guided-buying .slick-dots li {
      position: relative;
      display: inline-block;
      margin: 0 !important;
      width: 15px !important;
      height: 15px !important;
      padding: 0;
      cursor: pointer; }
      .guided-buying .slick-dots li button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 15px !important;
        height: 15px !important;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent; }
        .guided-buying .slick-dots li button:hover::before, .guided-buying .slick-dots li button:focus::before {
          opacity: 1; }
        .guided-buying .slick-dots li button::before {
          font-family: 'slick';
          font-size: 30px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          content: '•';
          text-align: center;
          opacity: 0.4;
          color: black;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
      .guided-buying .slick-dots li.slick-active {
        background: unset;
        border-radius: 0; }
      .guided-buying .slick-dots li.slick-active button::before {
        opacity: 1;
        color: black; }

.enhanced-guided-buying-wrapper .slick-scrollbar-cta {
  bottom: -5px; }

.enhanced-guided-buying {
  overflow: hidden;
  column-gap: 0.2rem; }
  @media (min-width: 992px) {
    .enhanced-guided-buying {
      column-gap: 0.5rem; } }
  @media (max-width: 768.98px) {
    .enhanced-guided-buying {
      margin-right: 2px; } }
  .enhanced-guided-buying .headline-text {
    font-weight: 300 !important;
    font-size: 1.5rem !important;
    line-height: 2rem; }
  .enhanced-guided-buying .slide-arrow {
    transform: translate(50%, 50%); }
  .enhanced-guided-buying .prev-arrow,
  .enhanced-guided-buying .next-arrow {
    background-color: rgba(255, 255, 255, 0.75); }
    .enhanced-guided-buying .prev-arrow::after,
    .enhanced-guided-buying .next-arrow::after {
      filter: invert(0); }
  .enhanced-guided-buying .slick-list {
    min-width: 100%; }
  .enhanced-guided-buying .slick-track {
    display: flex;
    column-gap: 0.3rem; }
    @media (min-width: 992px) {
      .enhanced-guided-buying .slick-track .slick-slide {
        padding-right: 0; } }
  .enhanced-guided-buying .gb-tile {
    flex-grow: 1;
    flex-basis: 100%;
    position: relative;
    justify-content: center;
    align-items: center; }
    .enhanced-guided-buying .gb-tile a {
      display: block; }
    .enhanced-guided-buying .gb-tile .tile-img {
      z-index: 1;
      border-radius: 0 !important;
      object-fit: cover;
      position: relative; }
    .enhanced-guided-buying .gb-tile .arrow-right {
      display: inline-block;
      width: 14px; }
    .enhanced-guided-buying .gb-tile .gb-tile-text-block {
      z-index: 2;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 1rem; }
      @media (min-width: 992px) {
        .enhanced-guided-buying .gb-tile .gb-tile-text-block {
          width: 100%; } }
      @media (min-width: 992px) {
        .enhanced-guided-buying .gb-tile .gb-tile-text-block.two-tiles {
          width: 50%; } }
      @media (min-width: 992px) {
        .enhanced-guided-buying .gb-tile .gb-tile-text-block.three-tiles {
          width: 70%; } }
      .enhanced-guided-buying .gb-tile .gb-tile-text-block .link-text {
        margin: 0;
        font-size: 1.5rem;
        font-family: "ITC Garamond W01";
        font-weight: bold;
        color: #fff; }
        @media (max-width: 768.98px) {
          .enhanced-guided-buying .gb-tile .gb-tile-text-block .link-text {
            font-size: 1rem; } }
      .enhanced-guided-buying .gb-tile .gb-tile-text-block .link-text::after {
        content: none; }
      .enhanced-guided-buying .gb-tile .gb-tile-text-block .link-subtext {
        text-align: center; }
    .enhanced-guided-buying .gb-tile .subtext {
      color: #5c6268;
      line-height: 1.25rem; }
      @media (max-width: 768.98px) {
        .enhanced-guided-buying .gb-tile .subtext {
          font-size: 0.75rem; } }
    .enhanced-guided-buying .gb-tile a,
    .enhanced-guided-buying .gb-tile .subtext {
      position: relative; }

.overlay-image-container {
  z-index: 1;
  max-width: 3.5625rem;
  margin: 1rem; }
  @media (min-width: 992px) {
    .overlay-image-container {
      max-width: 4.375rem; } }

.accordion-faq {
  border-bottom: 1px solid #ccc; }
  .accordion-faq .header-faq {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 300; }
    .accordion-faq .header-faq .icon {
      right: 0;
      top: 0.9375rem; }
    .accordion-faq .header-faq .up {
      display: none; }
    .accordion-faq .header-faq:not(.collapsed) .down {
      display: none; }
    .accordion-faq .header-faq:not(.collapsed) .up {
      display: inline-block; }

/* overriding the bootstrap header sizes */
.card-component {
  overflow-wrap: break-word; }
  .card-component-title__img {
    width: 1.5rem;
    height: 1.5rem; }
  .card-component-footer__btn {
    width: 100%; }
    @media (min-width: 992px) {
      .card-component-footer__btn {
        width: 12.5rem; } }

.hero-banner {
  display: flex;
  overflow: hidden;
  position: relative; }
  .hero-banner .hero-banner-container {
    display: flex;
    height: 100%;
    padding: 16px;
    width: 100%; }
    @media (min-width: 992px) {
      .hero-banner .hero-banner-container {
        padding: 50px; } }
  .hero-banner .embedded-component {
    width: 100%; }
    .hero-banner .embedded-component:empty {
      display: none; }
    .hero-banner .embedded-component .experience-region:empty {
      display: none; }
  .hero-banner.short {
    min-height: 150px; }
    @media (min-width: 769px) {
      .hero-banner.short {
        min-height: 80px; } }
  .hero-banner.medium {
    min-height: 250px; }
    @media (min-width: 769px) {
      .hero-banner.medium {
        min-height: 300px; } }
    @media (min-width: 992px) {
      .hero-banner.medium {
        min-height: 350px; } }
  .hero-banner.tall {
    min-height: 211px; }
    @media (min-width: 769px) {
      .hero-banner.tall {
        min-height: 650px; } }
    @media (min-width: 992px) {
      .hero-banner.tall {
        min-height: 700px; } }
  .hero-banner.screen-height {
    min-height: 100vh; }
    .fixed-header ~ div .hero-banner.screen-height {
      min-height: calc(100vh - 50px); }
      @media (min-width: 769px) {
        .fixed-header ~ div .hero-banner.screen-height {
          min-height: calc(100vh - 70px); } }
      @media (min-width: 992px) {
        .fixed-header ~ div .hero-banner.screen-height {
          min-height: calc(100vh - 64px); } }
    .experience-main > .experience-component:first-child .hero-banner.screen-height {
      min-height: calc(100vh - 91px); }
      @media (min-width: 769px) {
        .experience-main > .experience-component:first-child .hero-banner.screen-height {
          min-height: calc(100vh - 111px); } }
      @media (min-width: 992px) {
        .experience-main > .experience-component:first-child .hero-banner.screen-height {
          min-height: calc(100vh - 105px); } }

.hero-banner-media {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  width: 100%; }
  .hero-banner-media.hero-banner-media-tablet, .hero-banner-media.hero-banner-media-desktop {
    display: none; }
  @media (min-width: 769px) {
    .hero-banner-media.hero-banner-media-mobile, .hero-banner-media.hero-banner-media-desktop {
      display: none; }
    .hero-banner-media.hero-banner-media-tablet {
      display: block; } }
  @media (min-width: 992px) {
    .hero-banner-media.hero-banner-media-mobile, .hero-banner-media.hero-banner-media-tablet {
      display: none; }
    .hero-banner-media.hero-banner-media-desktop {
      display: block; } }

@media (max-width: 768.98px) {
  .hero-banner.half-and-half {
    height: auto; }
    .hero-banner.half-and-half .hero-banner-container {
      flex-flow: column !important; }
    .hero-banner.half-and-half .hero-banner-media {
      margin: -16px 0 16px -32px;
      position: relative;
      top: 0;
      transform: translateX(50%);
      width: calc(100% + 32px); }
    .hero-banner.half-and-half.short {
      min-height: 0; }
      .hero-banner.half-and-half.short .hero-banner-media {
        height: 150px; }
    .hero-banner.half-and-half.medium {
      min-height: 0; }
      .hero-banner.half-and-half.medium .hero-banner-media {
        height: 250px; }
    .hero-banner.half-and-half.tall, .hero-banner.half-and-half.screen-height {
      min-height: 0; }
      .hero-banner.half-and-half.tall .hero-banner-media, .hero-banner.half-and-half.screen-height .hero-banner-media {
        height: 211px; } }

@media (min-width: 769px) and (max-width: 991.98px) {
  .hero-banner.half-and-half .hero-banner-container:not(.flex-md-column) .hero-banner-media {
    transform: translateY(-50%); }
  .hero-banner.half-and-half .hero-banner-container:not(.flex-md-column).justify-content-md-start .hero-banner-media {
    left: auto;
    right: 0; }
  .hero-banner.half-and-half .hero-banner-container:not(.flex-md-column).justify-content-md-end .hero-banner-media {
    left: 0;
    right: auto; } }

@media (min-width: 992px) {
  .hero-banner.half-and-half .hero-banner-media {
    transform: translateY(-50%); }
  .hero-banner.half-and-half.justify-content-md-start .hero-banner-media {
    left: auto;
    right: 0; }
  .hero-banner.half-and-half.justify-content-md-end .hero-banner-media {
    left: 0;
    right: auto; } }

@media (max-width: 768.98px) {
  .hero-banner .flex-column .hero-banner-media {
    margin: -16px 0 16px -32px;
    position: relative;
    top: 0;
    transform: translateX(50%);
    width: calc(100% + 32px); }
  .hero-banner.short .flex-column {
    min-height: 0; }
    .hero-banner.short .flex-column .hero-banner-media {
      height: 150px; }
  .hero-banner.medium .flex-column {
    min-height: 0; }
    .hero-banner.medium .flex-column .hero-banner-media {
      height: 250px; }
  .hero-banner.tall .flex-column,
  .hero-banner.screen-height .flex-column {
    min-height: 0; }
    .hero-banner.tall .flex-column .hero-banner-media,
    .hero-banner.screen-height .flex-column .hero-banner-media {
      height: 350px; }
  .hero-banner.half-and-half .flex-column[class*="justify-content-md"] .hero-banner-media {
    left: auto;
    max-width: calc(100% + 32px);
    right: 50%; } }

@media (min-width: 769px) and (max-width: 991.98px) {
  .hero-banner .flex-md-column .hero-banner-media {
    margin: -16px 0 16px -32px;
    position: relative;
    top: 0;
    transform: translateX(50%);
    width: calc(100% + 32px); }
  .hero-banner.short .flex-md-column {
    min-height: 0; }
    .hero-banner.short .flex-md-column .hero-banner-media {
      height: 150px; }
  .hero-banner.medium .flex-md-column {
    min-height: 0; }
    .hero-banner.medium .flex-md-column .hero-banner-media {
      height: 250px; }
  .hero-banner.tall .flex-md-column,
  .hero-banner.screen-height .flex-md-column {
    min-height: 0; }
    .hero-banner.tall .flex-md-column .hero-banner-media,
    .hero-banner.screen-height .flex-md-column .hero-banner-media {
      height: 350px; }
  .hero-banner.half-and-half .flex-md-column[class*="justify-content-md"] .hero-banner-media {
    left: auto;
    max-width: calc(100% + 32px);
    right: 50%; } }

.how-it-fits .row::-webkit-scrollbar {
  width: unset;
  height: 5px; }

.why-youll-love-it-container .slick-slide {
  padding-right: 2px; }

.why-youll-love-it-container .why-youll-love-it-subheader {
  color: #5c6268;
  font-size: 14px;
  line-height: 20px; }

.why-youll-love-it-container .card {
  overflow: hidden; }
  .why-youll-love-it-container .card.slides-3 {
    width: 31.5%; }
  .why-youll-love-it-container .card.slides-2 {
    width: 48.5%; }
  .why-youll-love-it-container .card .card-title {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px; }
    @media (min-width: 1200px) {
      .why-youll-love-it-container .card .card-title {
        font-size: 24px;
        line-height: 26px; } }
    @media (min-width: 769px) {
      .why-youll-love-it-container .card .card-title {
        margin-bottom: 12px; } }
  .why-youll-love-it-container .card .card-body {
    color: #5c6268;
    font-size: 14px;
    line-height: 20px; }
    @media (min-width: 1200px) {
      .why-youll-love-it-container .card .card-body {
        font-size: 16px;
        line-height: 24px; } }
  .why-youll-love-it-container .card video {
    height: initial; }

.cat-nav-guided-buying .slick-list {
  min-width: 100%; }

.cat-nav-guided-buying .intro {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1.5rem;
  padding: 2.5rem 2.5rem 0.5rem; }
  @media (max-width: 991.98px) {
    .cat-nav-guided-buying .intro {
      padding: 1.5rem 0 1rem 0.5rem;
      margin-bottom: 0;
      font-size: 1rem; } }

.cat-nav-guided-buying .only-mobile .stacked-layout {
  flex: 0 0 100%;
  max-width: 100%; }

.cat-nav-guided-buying .markup-container p {
  color: #5c6268;
  font-size: 14px; }

.cat-nav-guided-buying .markup-container.biggaramond h1,
.cat-nav-guided-buying .markup-container.biggaramond h2,
.cat-nav-guided-buying .markup-container.biggaramond h3,
.cat-nav-guided-buying .markup-container.biggaramond h4,
.cat-nav-guided-buying .markup-container.biggaramond h5,
.cat-nav-guided-buying .markup-container.biggaramond h6 {
  font-family: "ITC Garamond W01" !important;
  font-size: 52px !important;
  color: #151415 !important; }

.cat-nav-guided-buying .markup-container.mediumgaramond h1,
.cat-nav-guided-buying .markup-container.mediumgaramond h2,
.cat-nav-guided-buying .markup-container.mediumgaramond h3,
.cat-nav-guided-buying .markup-container.mediumgaramond h4,
.cat-nav-guided-buying .markup-container.mediumgaramond h5,
.cat-nav-guided-buying .markup-container.mediumgaramond h6 {
  font-family: "ITC Garamond W01" !important;
  font-size: 32px !important;
  color: #151415 !important; }

.cat-nav-guided-buying .markup-container.smallgaramond h1,
.cat-nav-guided-buying .markup-container.smallgaramond h2,
.cat-nav-guided-buying .markup-container.smallgaramond h3,
.cat-nav-guided-buying .markup-container.smallgaramond h4,
.cat-nav-guided-buying .markup-container.smallgaramond h5,
.cat-nav-guided-buying .markup-container.smallgaramond h6 {
  font-family: "ITC Garamond W01" !important;
  font-size: 20px !important;
  color: #151415 !important; }

@media (max-width: 768.98px) {
  .cat-nav-guided-buying .markup-container.biggaramond h1,
  .cat-nav-guided-buying .markup-container.biggaramond h2,
  .cat-nav-guided-buying .markup-container.biggaramond h3,
  .cat-nav-guided-buying .markup-container.biggaramond h4,
  .cat-nav-guided-buying .markup-container.biggaramond h5,
  .cat-nav-guided-buying .markup-container.biggaramond h6, .cat-nav-guided-buying .markup-container.mediumgaramond h1,
  .cat-nav-guided-buying .markup-container.mediumgaramond h2,
  .cat-nav-guided-buying .markup-container.mediumgaramond h3,
  .cat-nav-guided-buying .markup-container.mediumgaramond h4,
  .cat-nav-guided-buying .markup-container.mediumgaramond h5,
  .cat-nav-guided-buying .markup-container.mediumgaramond h6, .cat-nav-guided-buying .markup-container.smallgaramond h1,
  .cat-nav-guided-buying .markup-container.smallgaramond h2,
  .cat-nav-guided-buying .markup-container.smallgaramond h3,
  .cat-nav-guided-buying .markup-container.smallgaramond h4,
  .cat-nav-guided-buying .markup-container.smallgaramond h5,
  .cat-nav-guided-buying .markup-container.smallgaramond h6 {
    font-size: 20px !important; } }

.cat-nav-guided-buying .markup-container.bigproxima h1,
.cat-nav-guided-buying .markup-container.bigproxima h2,
.cat-nav-guided-buying .markup-container.bigproxima h3,
.cat-nav-guided-buying .markup-container.bigproxima h4,
.cat-nav-guided-buying .markup-container.bigproxima h5,
.cat-nav-guided-buying .markup-container.bigproxima h6 {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #151415 !important; }

@media (max-width: 768.98px) {
  .cat-nav-guided-buying .markup-container.bigproxima h1,
  .cat-nav-guided-buying .markup-container.bigproxima h2,
  .cat-nav-guided-buying .markup-container.bigproxima h3,
  .cat-nav-guided-buying .markup-container.bigproxima h4,
  .cat-nav-guided-buying .markup-container.bigproxima h5,
  .cat-nav-guided-buying .markup-container.bigproxima h6 {
    font-size: 16px !important; } }

.cat-nav-guided-buying .markup-container.smallproxima h1,
.cat-nav-guided-buying .markup-container.smallproxima h2,
.cat-nav-guided-buying .markup-container.smallproxima h3,
.cat-nav-guided-buying .markup-container.smallproxima h4,
.cat-nav-guided-buying .markup-container.smallproxima h5,
.cat-nav-guided-buying .markup-container.smallproxima h6 {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #151415 !important; }

@media (max-width: 768.98px) {
  .cat-nav-guided-buying .markup-container.smallproxima h1,
  .cat-nav-guided-buying .markup-container.smallproxima h2,
  .cat-nav-guided-buying .markup-container.smallproxima h3,
  .cat-nav-guided-buying .markup-container.smallproxima h4,
  .cat-nav-guided-buying .markup-container.smallproxima h5,
  .cat-nav-guided-buying .markup-container.smallproxima h6 {
    font-size: 14px !important; } }

.cat-nav-guided-buying .category-carousel .slick-slide, .cat-nav-guided-buying .carousel-mobile .slick-slide {
  padding: 0 0.2px !important;
  margin-bottom: 0 !important; }
  .cat-nav-guided-buying .category-carousel .slick-slide > div, .cat-nav-guided-buying .carousel-mobile .slick-slide > div {
    padding: 0 0.2px !important;
    margin-bottom: 0 !important; }

.cat-nav-guided-buying .category-carousel .col-12, .cat-nav-guided-buying .carousel-mobile .col-12 {
  padding: 0 0.2px !important;
  margin-bottom: 0 !important; }

.cat-nav-guided-buying .category.only-desktop {
  padding-left: 7px;
  padding-right: 0;
  width: 100%; }

.cat-nav-guided-buying .only-desktop .col-2,
.cat-nav-guided-buying .only-desktop .col-3 {
  padding: 0 0.5px !important;
  margin-bottom: 0 !important; }

.cat-nav-guided-buying .only-desktop .col-4 {
  padding: 1px !important;
  margin-bottom: 0 !important; }

.cat-nav-guided-buying .only-desktop .col-auto {
  padding: 0 0.5px !important;
  flex: 0 0 20%;
  max-width: 20%; }

.cat-nav-guided-buying .only-desktop .col-1 {
  flex: 0 0 14.2%;
  max-width: 15%;
  padding-left: 0.5px;
  padding-right: 0.5px; }

.cat-nav-guided-buying .only-desktop .max-images {
  flex: 0 0 12.5%;
  max-width: 14%;
  padding-left: 0.5px;
  padding-right: 0.5px; }

.cat-nav-guided-buying .only-mobile {
  padding-bottom: 0 !important; }
  .cat-nav-guided-buying .only-mobile p {
    font-size: 12px;
    line-height: 1rem; }
  .cat-nav-guided-buying .only-mobile [class*="col-"],
  .cat-nav-guided-buying .only-mobile .col-6,
  .cat-nav-guided-buying .only-mobile .col-12 {
    padding: 1px !important;
    margin-bottom: 0 !important; }
  .cat-nav-guided-buying .only-mobile .carousel-mobile {
    margin: 0 -0.1px !important;
    padding-bottom: 0 !important; }
    .cat-nav-guided-buying .only-mobile .carousel-mobile .d-flex {
      margin-bottom: 0 !important; }

.cat-nav-guided-buying .img-container {
  position: relative;
  overflow: hidden; }

.cat-nav-guided-buying .headline-text {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  overflow: hidden;
  white-space: normal;
  font-family: "ITC Garamond W01" !important;
  font-size: 2rem !important;
  line-height: 2rem;
  z-index: 1; }
  .cat-nav-guided-buying .headline-text.top {
    top: 24px; }
  .cat-nav-guided-buying .headline-text.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%; }
  .cat-nav-guided-buying .headline-text.bottom {
    bottom: 24px; }
  .cat-nav-guided-buying .headline-text.big {
    font-size: 52px !important; }
  @media (max-width: 768.98px) {
    .cat-nav-guided-buying .headline-text {
      font-size: 20px !important; } }
  .cat-nav-guided-buying .headline-text.black {
    color: #151415 !important; }
  .cat-nav-guided-buying .headline-text.white {
    color: #f2f2f3 !important; }

.slick-scrollbar-cta .row .bottom-cta-container {
  margin-left: 1rem; }
  .slick-scrollbar-cta .row .bottom-cta-container .nb-button {
    margin: 0;
    min-width: auto; }

.keyboard-focus .cat-nav-guided-buying .img-container a:focus img {
  outline: 3px solid #015ecc;
  outline-offset: -2px; }

.hcb-container-main {
  margin-bottom: 2px; }
  .hcb-container-main.overlay {
    position: relative;
    overflow: hidden; }
  .hcb-container-main.center {
    justify-content: center; }
  .hcb-container-main.right {
    justify-content: right; }
  .hcb-container-main .nb-pd-video .video-controls.overlay.right {
    right: 0;
    left: 24px; }
  .hcb-container-main .nb-pd-video .hcb-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    pointer-events: none;
    z-index: 2; }
    .hcb-container-main .nb-pd-video .hcb-overlay.bg-white {
      background-color: #fff; }
  .hcb-container-main .hcb-container {
    max-width: 700px; }
    .hcb-container-main .hcb-container.mobile-base {
      padding-top: 1.25rem !important;
      width: 100%; }
    .hcb-container-main .hcb-container.overlay {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      z-index: 3; }
      .hcb-container-main .hcb-container.overlay.vcenter {
        top: 50%; }
      .hcb-container-main .hcb-container.overlay.vbottom {
        top: 60%; }
        @media (min-width: 992px) {
          .hcb-container-main .hcb-container.overlay.vbottom {
            top: 60%; } }
        @media (min-width: 1200px) {
          .hcb-container-main .hcb-container.overlay.vbottom {
            top: 66%; } }
        @media (max-width: 768.98px) {
          .hcb-container-main .hcb-container.overlay.vbottom {
            top: 66%; } }
        .hcb-container-main .hcb-container.overlay.vbottom.pgp {
          top: 60% !important; }
          .hcb-container-main .hcb-container.overlay.vbottom.pgp.v3 {
            top: 65% !important; }
          .hcb-container-main .hcb-container.overlay.vbottom.pgp.v2 {
            top: 75% !important; }
          .hcb-container-main .hcb-container.overlay.vbottom.pgp.v1 {
            top: 80% !important; }
        .hcb-container-main .hcb-container.overlay.vbottom.v3 {
          top: 67%; }
          @media (max-width: 991.98px) {
            .hcb-container-main .hcb-container.overlay.vbottom.v3 {
              top: 67%; } }
        .hcb-container-main .hcb-container.overlay.vbottom.v2 {
          top: 70%; }
          @media (max-width: 991.98px) {
            .hcb-container-main .hcb-container.overlay.vbottom.v2 {
              top: 67%; } }
        .hcb-container-main .hcb-container.overlay.vbottom.v1 {
          top: 85%; }
          @media (max-width: 991.98px) {
            .hcb-container-main .hcb-container.overlay.vbottom.v1 {
              top: 90%; } }
      .hcb-container-main .hcb-container.overlay.left {
        text-align: left;
        left: 355px; }
        @media screen and (max-width: 544px) {
          .hcb-container-main .hcb-container.overlay.left {
            left: 50%; } }
      .hcb-container-main .hcb-container.overlay.center {
        left: 50%;
        justify-content: center; }
      .hcb-container-main .hcb-container.overlay.right {
        text-align: right;
        right: -275px; }
        @media screen and (max-width: 544px) {
          .hcb-container-main .hcb-container.overlay.right {
            right: -50%; } }
    .hcb-container-main .hcb-container.center {
      justify-content: center; }
    .hcb-container-main .hcb-container.left {
      text-align: left; }
    .hcb-container-main .hcb-container.right {
      text-align: right;
      justify-content: right; }
    .hcb-container-main .hcb-container .headline-container {
      font-family: "ITC Garamond W01" !important;
      font-size: 3.25rem;
      line-height: 3.5rem;
      width: 100%;
      margin: auto; }
      .hcb-container-main .hcb-container .headline-container.center {
        text-align: center;
        justify-content: center; }
      .hcb-container-main .hcb-container .headline-container.right {
        text-align: right;
        justify-content: right;
        width: 100%; }
      .hcb-container-main .hcb-container .headline-container.left {
        text-align: left;
        justify-content: left;
        width: 100%; }
      @media (max-width: 991.98px) {
        .hcb-container-main .hcb-container .headline-container {
          font-size: 2rem;
          line-height: 2.25rem; } }
      @media screen and (max-width: 544px) {
        .hcb-container-main .hcb-container .headline-container {
          margin-bottom: 0 !important; } }
    .hcb-container-main .hcb-container .intro-container,
    .hcb-container-main .hcb-container .body-container {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
      width: 100%;
      margin: auto; }
      .hcb-container-main .hcb-container .intro-container.center,
      .hcb-container-main .hcb-container .body-container.center {
        text-align: center;
        justify-content: center; }
      .hcb-container-main .hcb-container .intro-container.right,
      .hcb-container-main .hcb-container .body-container.right {
        text-align: right;
        justify-content: right; }
    .hcb-container-main .hcb-container .body-container p {
      margin-bottom: 0; }
    .hcb-container-main .hcb-container .body-container.light-color-mode h1,
    .hcb-container-main .hcb-container .body-container.light-color-mode h2,
    .hcb-container-main .hcb-container .body-container.light-color-mode h3,
    .hcb-container-main .hcb-container .body-container.light-color-mode h4,
    .hcb-container-main .hcb-container .body-container.light-color-mode p {
      color: #fff !important; }
    @media (max-width: 768.98px) {
      .hcb-container-main .hcb-container .body-container {
        margin-bottom: 0.5rem !important; } }
    .hcb-container-main .hcb-container .cta-container.cta-update {
      width: 100%; }
    .hcb-container-main .hcb-container .cta-container.center {
      justify-content: center;
      margin-left: auto;
      margin-right: auto; }
    .hcb-container-main .hcb-container .cta-container.left {
      justify-content: left; }
    .hcb-container-main .hcb-container .cta-container.right {
      justify-content: right; }
    .hcb-container-main .hcb-container .cta-container .cta-box .nb-button {
      font-size: 0.875rem;
      line-height: 1rem;
      border-radius: 4px;
      padding: 0.625rem 1rem; }
  .hcb-container-main .disclaimer-container {
    font-size: 0.75rem;
    line-height: 1.125rem; }
    .hcb-container-main .disclaimer-container.mobile-base {
      color: #5c6268; }
      .hcb-container-main .disclaimer-container.mobile-base span {
        color: #5c6268 !important;
        font-size: 0.75rem;
        line-height: 1.125rem; }
    .hcb-container-main .disclaimer-container.overlay {
      position: absolute;
      bottom: 1rem;
      z-index: 3; }
      @media (max-width: 991.98px) {
        .hcb-container-main .disclaimer-container.overlay {
          bottom: 0.5rem; } }
      .hcb-container-main .disclaimer-container.overlay.center {
        text-align: center; }
        @media (max-width: 991.98px) {
          .hcb-container-main .disclaimer-container.overlay.center {
            padding-left: 5px !important;
            padding-right: 5px !important; } }
      @media (max-width: 991.98px) {
        .hcb-container-main .disclaimer-container.overlay {
          bottom: 0.1rem; } }
    .hcb-container-main .disclaimer-container.left {
      text-align: left; }
    .hcb-container-main .disclaimer-container.center {
      text-align: center; }
      .hcb-container-main .disclaimer-container.center .disclaimer-box {
        margin-left: auto;
        margin-right: auto; }
    .hcb-container-main .disclaimer-container.right {
      text-align: right; }
      .hcb-container-main .disclaimer-container.right .disclaimer-box {
        margin-left: auto; }
    .hcb-container-main .disclaimer-container .disclaimer-box {
      max-width: 1000px; }
      .hcb-container-main .disclaimer-container .disclaimer-box.light-color-mode h1,
      .hcb-container-main .disclaimer-container .disclaimer-box.light-color-mode h2,
      .hcb-container-main .disclaimer-container .disclaimer-box.light-color-mode h3,
      .hcb-container-main .disclaimer-container .disclaimer-box.light-color-mode h4,
      .hcb-container-main .disclaimer-container .disclaimer-box.light-color-mode p {
        color: #fff !important; }
    @media (max-width: 991.98px) {
      .hcb-container-main .disclaimer-container {
        line-height: 1rem; } }

div[class*='experience'][class*='column'] .hcb-container-main .hcb-video .video-controls {
  top: 30px;
  right: 14px; }
  div[class*='experience'][class*='column'] .hcb-container-main .hcb-video .video-controls.aspect1x1 .video-controls {
    right: 90px; }
    @media (max-width: 991.98px) {
      div[class*='experience'][class*='column'] .hcb-container-main .hcb-video .video-controls.aspect1x1 .video-controls {
        right: 55px; } }
  div[class*='experience'][class*='column'] .hcb-container-main .hcb-video .video-controls.aspect4x5 .video-controls {
    right: 90px; }
    @media (max-width: 991.98px) {
      div[class*='experience'][class*='column'] .hcb-container-main .hcb-video .video-controls.aspect4x5 .video-controls {
        right: 55px; } }
  div[class*='experience'][class*='column'] .hcb-container-main .hcb-video .video-controls.aspect9x16 .video-controls {
    right: 90px; }
    @media (max-width: 991.98px) {
      div[class*='experience'][class*='column'] .hcb-container-main .hcb-video .video-controls.aspect9x16 .video-controls {
        right: 55px; } }
  div[class*='experience'][class*='column'] .hcb-container-main .hcb-video .video-controls.aspect5x4 .video-controls {
    right: 90px; }
    @media (max-width: 991.98px) {
      div[class*='experience'][class*='column'] .hcb-container-main .hcb-video .video-controls.aspect5x4 .video-controls {
        right: 55px; } }
  div[class*='experience'][class*='column'] .hcb-container-main .hcb-video .video-controls.aspect16x9 .video-controls {
    right: 90px; }
    @media (max-width: 991.98px) {
      div[class*='experience'][class*='column'] .hcb-container-main .hcb-video .video-controls.aspect16x9 .video-controls {
        right: 55px; } }

div[class*='experience'][class*='column'] .hcb-container-main .hcb-container {
  max-width: 550px; }
  div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.left {
    text-align: left; }
  div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.center {
    text-align: center;
    justify-content: center; }
  div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.right {
    text-align: right;
    justify-content: right; }
  div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.vbottom {
    top: 50%; }
    div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.vbottom.v3 {
      top: 53%; }
    div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.vbottom.v2 {
      top: 66%; }
    div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.vbottom.v1 {
      top: 75%; }
  div[class*='experience'][class*='column'] .hcb-container-main .hcb-container .body-container.center,
  div[class*='experience'][class*='column'] .hcb-container-main .hcb-container .headline-container.center {
    width: 100%; }
  div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.hbc-video {
    padding-left: 2rem;
    padding-right: 2rem; }
    div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.hbc-video .headline-container {
      line-height: 2.8rem; }
      div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.hbc-video .headline-container.left {
        width: 100%;
        text-align: left; }
      div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.hbc-video .headline-container.right {
        width: 100%;
        text-align: right;
        justify-content: right; }
    div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.hbc-video .body-container {
      line-height: 1rem; }
      div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.hbc-video .body-container.left {
        width: 100%;
        text-align: left; }
      div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.hbc-video .body-container.right {
        width: 100%;
        text-align: right;
        justify-content: right; }
      div[class*='experience'][class*='column'] .hcb-container-main .hcb-container.hbc-video .body-container p {
        margin-bottom: 0; }

div[class*='experience'][class*='column'] .hcb-container-main .disclaimer-container {
  font-size: 0.75rem;
  line-height: 1.125rem; }
  div[class*='experience'][class*='column'] .hcb-container-main .disclaimer-container.left {
    text-align: left; }
  div[class*='experience'][class*='column'] .hcb-container-main .disclaimer-container.center {
    text-align: center; }
  div[class*='experience'][class*='column'] .hcb-container-main .disclaimer-container.right {
    text-align: right; }
    div[class*='experience'][class*='column'] .hcb-container-main .disclaimer-container.right .disclaimer-box {
      margin-left: auto; }
  div[class*='experience'][class*='column'] .hcb-container-main .disclaimer-container.hbc-video {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    line-height: 1rem !important; }
  div[class*='experience'][class*='column'] .hcb-container-main .disclaimer-container .disclaimer-box {
    max-width: 1000px; }

.multi-column-layout.pb-05 {
  padding-bottom: 0.125rem; }

.multi-column-layout.pb-4-5 {
  padding-bottom: 2rem; }

@media (min-width: 769px) {
  .multi-column-layout .col-12.pr-sm {
    padding-right: 0.125rem !important; } }

@media (max-width: 991.98px) {
  .multi-column-layout .col-12 {
    padding-bottom: 0.125rem !important; } }

.multi-column-layout .card {
  overflow: hidden; }

.multi-column-layout .hcb-container-main {
  margin: auto; }
