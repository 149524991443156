@import '../../../global/variables';
@import 'bootstrap/scss/mixins/breakpoints';

@mixin tight-spacing {
    padding: 0 0.2px !important;
    margin-bottom: 0 !important;
}

@mixin standard-spacing {
    padding: 1px !important;
    margin-bottom: 0 !important;
}

.cat-nav-guided-buying {
    .slick-list {
        min-width: 100%;
    }

    .intro {
        font-family: $body-font-family !important;
        font-size: 1.5rem;
        padding: 2.5rem 2.5rem 0.5rem;

        @include media-breakpoint-down(md) {
            padding: 1.5rem 0 1rem 0.5rem;
            margin-bottom: 0;
            font-size: 1rem;
        }
    }

    .only-mobile {
        .stacked-layout {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .markup-container {
        p {
            color: $gray-blue;
            font-size: 14px;
        }

        &.biggaramond {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-family: $nb-page-heading-font !important;
                font-size: 52px !important;
                color: $nb-black !important;
            }
        }

        &.mediumgaramond {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-family: $nb-page-heading-font !important;
                font-size: 32px !important;
                color: $nb-black !important;
            }
        }

        &.smallgaramond {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-family: $nb-page-heading-font !important;
                font-size: 20px !important;
                color: $nb-black !important;
            }
        }

        &.biggaramond,
        &.mediumgaramond,
        &.smallgaramond {
            @include media-breakpoint-down(sm) {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-size: 20px !important;
                }
            }
        }

        &.bigproxima {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-family: $body-font-family !important;
                font-size: 24px !important;
                font-weight: 700 !important;
                color: $nb-black !important;
            }

            @include media-breakpoint-down(sm) {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-size: 16px !important;
                }
            }
        }

        &.smallproxima {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-family: $body-font-family !important;
                font-size: 16px !important;
                font-weight: 700 !important;
                color: $nb-black !important;
            }

            @include media-breakpoint-down(sm) {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-size: 14px !important;
                }
            }
        }
    }

    %carousel-base {
        .slick-slide {
            @include tight-spacing;

            > div {
                @include tight-spacing;
            }
        }

        .col-12 {
            @include tight-spacing;
        }
    }

    .category-carousel,
    .carousel-mobile {
        @extend %carousel-base;
    }

    // Desktop-specific styles
    .category {
        &.only-desktop {
            padding-left: 7px;
            padding-right: 0;
            width: 100%;
        }
    }

    .only-desktop {
        .col-2,
        .col-3 {
            padding: 0 0.5px !important;
            margin-bottom: 0 !important;
        }

        .col-4 {
            @include standard-spacing;
        }

        .col-auto {
            padding: 0 0.5px !important;
            flex: 0 0 20%;
            max-width: 20%;
        }

        .col-1 {
            flex: 0 0 14.2%;
            max-width: 15%;
            padding-left: 0.5px;
            padding-right: 0.5px;
        }

        .max-images {
            flex: 0 0 12.5%;
            max-width: 14%;
            padding-left: 0.5px;
            padding-right: 0.5px;
        }
    }

    .only-mobile {
        padding-bottom: 0 !important;

        p {
            font-size: 12px;
            line-height: 1rem;
        }

        [class*="col-"],
        .col-6,
        .col-12 {
            @include standard-spacing;
        }

        .carousel-mobile {
            margin: 0 -0.1px !important;
            padding-bottom: 0 !important;

            @extend %carousel-base;

            .d-flex {
                margin-bottom: 0 !important;
            }
        }
    }

    .img-container {
        position: relative;
        overflow: hidden;
    }

    .headline-text {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        overflow: hidden;
        white-space: normal;
        font-family: $nb-page-heading-font !important;
        font-size: 2rem !important;
        line-height: 2rem;
        z-index: 1;

        &.top {
            top: 24px;
        }

        &.center {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
        }

        &.bottom {
            bottom: 24px;
        }

        &.big {
            font-size: 52px !important;
        }

        @include media-breakpoint-down(sm) {
            font-size: 20px !important;
        }

        &.black {
            color: $nb-black !important;
        }

        &.white {
            color: $ghost-white !important;
        }
    }
}

.slick-scrollbar-cta {
    .row {
        .bottom-cta-container {
            margin-left: 1rem;

            .nb-button {
                margin: 0;
                min-width: auto;
            }
        }
    }
}

.keyboard-focus .cat-nav-guided-buying {
    .img-container {
        a:focus img {
            outline: 3px solid $focus-outline-color;
            outline-offset: -2px;
        }
    }
}
