@import 'variables';
@import 'colors';
@import '../mixins/util';
@import '../variables';
@import 'bootstrap/scss/mixins/breakpoints';

$theme-colors: (
    'primary': $nb-red,
    'secondary': $trans,
    'danger': $nb-red
);

strong {
    font-weight: $fontweight-bold;
}

// spinner
.veil {
    z-index: 1001;
}

/* Works on Firefox */
* {
    scrollbar-width: auto; //NOSONAR
    scrollbar-color: #ccc #fff; //NOSONAR
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 7px;
}

*::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 8px;
}

*::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body {
    color: $black;

    .font-hero {
        font-family: $nb-page-heading-font;
        font-size: rem(96);
        line-height: rem(104);

        html[lang="ja"] & {
            font-size: rem(72);
            line-height: rem(80);
        }

        @include media-breakpoint-down(md) {
            font-size: rem(56);
            line-height: rem(60);

            html[lang="ja"] & {
                font-size: rem(42);
                line-height: rem(46);
            }
        }
    }

    .didot-font {
        font-family: $body-font-family;
        font-size: rem(24);
        line-height: rem(32);
        @include media-breakpoint-up(lg) {
            font-family: $nb-page-heading-font;
            font-size: rem(48);
            line-height: rem(56);
        }
    }

    .font-header {
        %heading-common-props {
            font-family: $body-font-family;
            letter-spacing: 0;
            font-weight: $fontweight-semibold;
        }

        &-1 {
            @extend %heading-common-props;

            font-size: rem(32);
            color: $black;
            line-height: rem(40);

            html[lang="ja"] & {
                font-size: rem(24);
                line-height: rem(32);
            }

            &--md {
                @include media-breakpoint-down(md) {
                    font-size: rem(24);
                    line-height: rem(32);
                }
            }
        }

        &-2 {
            @extend %heading-common-props;

            font-size: rem(24);
            color: $black;
            line-height: rem(32);

            html[lang="ja"] & {
                font-size: rem(18);
                line-height: rem(26);
            }

            &.mobile-font-small {
                @include media-breakpoint-down(md) {
                    font-size: rem(14);
                    line-height: rem(16);
                }
            }
        }

        &-3 {
            @extend %heading-common-props;

            font-size: rem(16);
            color: $black;
            line-height: rem(24);

            &.desktop-subhead-2 {
                @include media-breakpoint-up(md) {
                    font-size: rem(14);
                    line-height: rem(16);
                }
            }
        }

        &-4 {
            font-family: $body-font-family;
            letter-spacing: 0;
            font-size: rem(16);
            color: $white;
            line-height: rem(24);
            font-weight: $fontweight-bold;

            &.desktop-subhead-2 {
                @include media-breakpoint-up(md) {
                    font-size: rem(14);
                    line-height: rem(16);
                }
            }
        }
    }

    .font-subheader {
        &-1 {
            font-family: $body-font-family;
            font-size: rem(48);
            color: $black;
            letter-spacing: 0;
            line-height: rem(56);
        }

        &-2 {
            font-family: $body-font-family;
            font-size: rem(20);
            color: $black;
            letter-spacing: 0;
            line-height: rem(32);
            font-weight: $fontweight-semibold;
        }

        &-3 {
            font-family: $body-font-family;
            font-size: rem(14);
            color: $black;
            letter-spacing: 0;
            line-height: rem(24);
        }

        &-4 {
            color: $black;
            font-size: rem(16);
            font-weight: $fontweight-regular;
            letter-spacing: 0;
            line-height: rem(16);
        }
    }

    .font-body {
        font-family: $body-font-family;
        font-size: rem(14);
        color: $black;
        letter-spacing: 0;
        line-height: rem(24);
        font-weight: $fontweight-regular;

        &-small {
            font-family: $body-font-family;
            font-size: rem(12);
            color: $black;
            letter-spacing: 0;
            line-height: rem(18);
        }

        &-large {
            font-family: $body-font-family;
            font-size: rem(16);
            color: $black;
            letter-spacing: 0;
            line-height: rem(24);

            &.mobile-font-small {
                @include media-breakpoint-down(md) {
                    font-size: rem(14);
                    line-height: rem(20);
                }
            }
        }

        &-medium {
            font-family: $body-font-family;
            font-size: rem(16);
            color: $black;
            letter-spacing: 0;
            line-height: rem(20);
        }
    }

    .font-small {
        font-family: $body-font-family;
        font-size: rem(12);
        color: $black;
        letter-spacing: 0;
        line-height: rem(18);
    }

    .font-error {
        font-family: $body-font-family;
        font-size: rem(14);
        color: $nb-red;
        letter-spacing: 0;
        line-height: rem(24);
    }

    .font-link {
        font-family: $body-font-family;
        text-decoration: underline;
        font-size: rem(14);
        color: $black;
        letter-spacing: 0;
        line-height: rem(20);
        font-weight: $fontweight-semibold;

        &.bold {
            font-weight: $fontweight-bold;
        }

        &.no-text-decoration {
            text-decoration: none;
        }
    }

    .bold {
        font-weight: $fontweight-bold;
    }

    .normal {
        font-weight: $fontweight-light;
    }

    .regular {
        font-weight: $fontweight-regular;
    }

    .font-weight-semibold {
        font-weight: $fontweight-semibold;
    }

    .text-underline {
        text-decoration: underline;
    }

    .no-underline {
        text-decoration: none;
    }

    .lowercase {
        text-transform: lowercase;
    }

    @include media-breakpoint-up(lg) {
        .font-header-lg {
            &-1 {
                font-family: $body-font-family;
                font-size: rem(32);
                color: $black;
                letter-spacing: 0;
                line-height: rem(40);
                font-weight: $fontweight-semibold;
            }

            &-2 {
                font-family: $body-font-family;
                font-size: rem(24);
                color: $black;
                letter-spacing: 0;
                line-height: rem(32);
                font-weight: $fontweight-bold;
            }

            &-3 {
                font-family: $body-font-family;
                font-size: rem(16);
                color: $black;
                letter-spacing: 0;
                line-height: rem(24);
                font-weight: $fontweight-semibold;

                &.desktop-subhead-2 {
                    @include media-breakpoint-up(md) {
                        font-size: rem(14);
                        line-height: rem(16);
                    }
                }
            }
        }

        .font-subheader-lg {
            &-1 {
                font-family: $body-font-family;
                font-size: rem(48);
                color: $black;
                letter-spacing: 0;
                line-height: rem(56);
            }

            &-2 {
                font-family: $body-font-family;
                font-size: rem(20);
                color: $black;
                letter-spacing: 0;
                line-height: rem(32);
                font-weight: $fontweight-semibold;
            }

            &-3 {
                font-family: $body-font-family;
                font-size: rem(14);
                color: $black;
                letter-spacing: 0;
                line-height: rem(16);
            }
        }

        .font-body-lg {
            font-family: $body-font-family;
            font-size: rem(14);
            color: $black;
            letter-spacing: 0;
            line-height: rem(20);
            font-weight: $fontweight-regular;

            &-small {
                font-family: $body-font-family;
                font-size: rem(12);
                color: $black;
                letter-spacing: 0;
                line-height: rem(16);
            }

            &-large {
                font-family: $body-font-family;
                font-size: rem(16);
                color: $black;
                letter-spacing: 0;
                line-height: rem(24);

                &.mobile-font-small {
                    @include media-breakpoint-down(md) {
                        font-size: rem(14);
                        line-height: rem(20);
                    }
                }
            }

            &-medium {
                font-family: $body-font-family;
                font-size: rem(16);
                color: $black;
                letter-spacing: 0;
                line-height: rem(20);
            }
        }

        .font-small-lg {
            font-family: $body-font-family;
            font-size: rem(12);
            color: $black;
            letter-spacing: 0;
            line-height: rem(16);
        }

        .font-error-lg {
            font-family: $body-font-family;
            font-size: rem(14);
            color: $nb-red;
            letter-spacing: 0;
            line-height: rem(24);
        }

        .font-link-lg {
            font-family: $body-font-family;
            text-decoration: underline;
            font-size: rem(14);
            color: $black;
            letter-spacing: 0;
            line-height: rem(20);
            font-weight: $fontweight-semibold;

            &.bold {
                font-weight: $fontweight-bold;
            }
        }

        .bold-lg {
            font-weight: $fontweight-bold;
        }

        .normal-lg {
            font-weight: $fontweight-light;
        }

        .regular-lg {
            font-weight: $fontweight-regular;
        }

        .font-weight-semibold-lg {
            font-weight: $fontweight-semibold;
        }

        .text-underline-lg {
            text-decoration: underline;
        }

        .no-underline-lg {
            text-decoration: none;
        }

        .lowercase-lg {
            text-transform: lowercase;
        }
    }
}
