@import "../../../global/colors";
@import "../../../global/variables";
@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.table-component {
    td {
        font-weight: $fontweight-regular;

        a {
            text-decoration: underline;
        }
    }

    tr:first-child {
        th,
        td {
            font-weight: $fontweight-bold;
        }
    }

    &.cc-size-guide-table {
        table {
            table-layout: fixed;
            text-align: center;
        }

        tr {
            &:hover,
            &:focus {
                background-color: #585858;
                color: $white;

                h1,
                h2,
                h3,
                h4,
                a {
                    color: $white;
                }
            }

            &:first-child:hover,
            &:first-child:focus {
                background-color: rgba(0, 0, 0, 0.05);
                color: inherit;

                h1,
                h2,
                h3,
                h4,
                a {
                    color: inherit;
                }
            }
        }

        td,
        th {
            border-right: 3px solid $white;
            border-top: none;
            padding-left: 2px;
            padding-right: 2px;
            vertical-align: middle;
        }

        tr:first-child {
            th,
            td {
                color: #585858;
                font-size: $font-size12;
                font-weight: $fontweight-regular;

                h1,
                h2,
                h3,
                h4 {
                    color: #585858;
                }

                @include media-breakpoint-down(sm) {
                    padding-top: 20px;
                    padding-bottom: 20px;
                }

                @include media-breakpoint-up(md) {
                    font-size: 21px;
                }
            }
        }

        tr:nth-child(n+2) {
            td {
                font-size: $font-size14;

                &:hover,
                &:focus {
                    background-color: $nb-red;
                    color: $white;
                }
            }
        }
    }

    &.legacy-pdp-size-chart-table {
        table {
            border: 2px solid $silver-grey;
            text-align: center;

            tr {
                &:hover,
                &:focus {
                    background-color: $nb-red;
                    color: $white;

                    h1,
                    h2,
                    h3,
                    h4,
                    a {
                        color: $white;
                    }
                }

                &:first-child:hover,
                &:first-child:focus {
                    background-color: #f2f2f2;
                    color: inherit;

                    h1,
                    h2,
                    h3,
                    h4,
                    a {
                        color: inherit;
                    }
                }
            }

            th,
            td {
                vertical-align: middle;
            }
        }
    }

    &.pdp-size-chart-table {
        table {
            border: 1px solid $silver-grey;
            text-align: center;
            border-spacing: 0;
            border-collapse: separate;
            border-radius: 3px;
            margin-bottom: 0.5rem;

            .table-header {
                th {
                    padding-left: 7px;
                    border-top: none;
                }

                + tr {
                    background-color: #efefef !important;
                }
            }

            tr {
                text-align: left;
                padding-left: 7px;

                &:first-child {
                    background-color: #efefef !important;

                    td:first-child {
                        background-color: unset !important;
                    }

                    td {
                        border-top: none;
                    }
                }

                &:nth-of-type(odd) {
                    background-color: unset;
                }
            }

            th,
            td {
                width: 21%;
                padding-left: 7px;
                vertical-align: middle;
                border-right: 1px solid #ccc;

                &:first-child {
                    background-color: #efefef !important;
                }

                &:last-child {
                    border-right: none;
                }

                &:nth-child(0) {
                    background-color: unset;
                }
            }
        }

        &.table-responsive {
            width: 427px;
            padding: 0;
            margin: 0;
        }

        @at-root .experience-commerce_assets-TableComponent {
            overflow-x: auto;
        }
    }

    &.pdp-size-chart-table-customizable {
        table {
            min-width: 472px;
            border-spacing: 0;
            border-collapse: separate;
            border-radius: 3px;
            margin-bottom: 0.5rem;

            th,
            td {
                border: 1px solid $silver-grey;
                width: 20px;
                vertical-align: middle;
                text-align: center;
                font-weight: $fontweight-regular;
            }

            .spacer-row {
                height: 5px !important;
                padding: 0;
                border: none !important;
                background-color: unset;
            }

            .spacer-column {
                width: 0.75% !important;
                padding: 0;
                border: none !important;
                background-color: unset;

                @include media-breakpoint-down(sm) {
                    width: 0.5% !important;
                }

                @include media-breakpoint-down(xs) {
                    width: 0.75% !important;
                }
            }
        }

        &.table-responsive {
            padding: 0;
            margin: 0;
            overflow-x: visible;
        }
    }

    &.shipping-table {
        tr:first-child {
            th,
            td {
                border-bottom: 2px solid $silver-grey;
                vertical-align: bottom;
            }
        }
    }

    // 'Subheader 1' styling
    h1 {
        font-family: $nb-page-heading-font;
        font-size: rem(56);
        line-height: rem(60);
        @include media-breakpoint-up(md) {
            font-size: $font-size48;
            line-height: $font-size56;
        }
    }

    // 'Header 1' styling
    h2 {
        font-family: $body-font-family;
        font-size: $font-size32;
        font-weight: $fontweight-bold;
        line-height: $font-size40;
    }

    // 'Header 2' styling
    h3 {
        font-family: $body-font-family;
        font-size: $font-size24;
        font-weight: $fontweight-bold;
        line-height: $font-size32;
    }

    // using h4 as disclaimer
    h4 {
        font-family: $body-font-family;
        font-size: $font-size12;
        font-weight: $fontweight-regular;
        line-height: $font-size16;
    }

    p,
    li {
        font-family: $body-font-family;
        font-weight: $fontweight-regular;
    }

    b {
        font-weight: $fontweight-bold;
    }

    a {
        text-decoration: underline;
    }

    ul {
        list-style: disc inside;
    }

    ol {
        list-style: decimal inside;
    }

    h1,
    h2,
    h3,
    h4,
    p,
    li,
    a {
        margin-bottom: 0;
    }

    [align="left"] {
        text-align: left !important;
    }

    [align="center"] {
        text-align: center !important;
    }

    [align="right"] {
        text-align: right !important;
    }

    .spacer-row {
        height: 2%;
        padding: 0;
        border: none;
        background-color: unset;
    }

    .spacer-column {
        width: 2%;
        padding: 0;
        border: none;
        background-color: unset;
    }

    .sticky-row {
        position: sticky;
        top: 0;
    }

    .sticky-column {
        position: sticky;
        left: 0;
    }
}
